import React from 'react';
import styled , {css} from 'styled-components';
import {Box, Flex} from 'rebass';
import backIcon from '../icons/back_arrow_icon.svg'

const PaginationBar = styled.div`
  background:#fff;
  display:flex;
  align-items:center;
  justify-content: center;
`;

const Dot = styled.div`
    border-radius:50%;
    width:16px;
    height:16px;
    background:#D8D8D8;
    margin:4px;
`;


const FilledDot = styled(Dot)`
    background:#FFD900;
`;

const BackArrow = styled(Box)`
   position:absolute;
   left:16px;
`;

export default function Pagination(props) {

    //Props : pages, current

    let pagination = [];

    for(let i =0;i<props.pages;i++){
        if(props.current > i){
            pagination.push(<FilledDot/>);
        }else{
            pagination.push(<Dot/>);
        }
    }

  return (<PaginationBar {... props}>
  <BackArrow onClick={()=>{
                      window.history.back();
                   }}> <img src={backIcon}/></BackArrow>
      <Flex>{pagination}</Flex>
  </PaginationBar>);
}



