import React from 'react';
import Modal from 'styled-react-modal';
import {useState, useEffect} from 'react';
import Button from './Button.js';
import styled from 'styled-components';
import Subheading from './Subheading.js';
import {Link} from 'react-router-dom';
const StyledModal = Modal.styled`
  width: 20rem;
  height: 20rem;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 7px 27px 0 #d3c07f;
  padding:1rem;
  box-sizing:border-box;
  text-align:left;
`;

const CancelButton = styled.div`
&, & a{
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.38) !important;
  text-decoration:none;
}
   
`;

 
export default function FancyModalButton(props) {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  
  // useEffect(()=>{

  //     if(!props.isOpen && isOpen == false){
  //        setIsOpen(false);
  //      }else if(props.isOpen && isOpen == true){
  //        setIsOpen(true);
  //    }
  // });

  //if(props.isOpen){setIsOpen(true)}
 
  const  toggleModal = (e)=> {
    setIsOpen(!isOpen);
  }

 

    return (
        <StyledModal
          key={Math.random()}
          isOpen={isOpen && props.isOpen}
          onBackgroundClick={()=>{}}
          onEscapeKeydown={toggleModal}
          backgroundProps={{style:{backgroundColor:"none", width:"100%", height:"100%"}}}
          >
         
          <Subheading>Now let’s quickly finish your profile to customize your experience…</Subheading>

          <CancelButton onClick={toggleModal} ><Link to="/jobs">Finish later</Link></CancelButton>
          
          <Button onClick={toggleModal} primary><Link to="/register/become-elite">Continue</Link></Button>
        </StyledModal>
    )
  
}