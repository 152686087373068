import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Flex, Box} from 'rebass';
import { Label, Checkbox, Radio, Slider, Input } from '@rebass/forms'
import {getIcon} from '../components/Icons';
import Button from '../components/Button.js';
import Options from '../components/Options.js';
import Score from '../components/Score.js';
import styled from 'styled-components';
import {useHistory, withRouter} from 'react-router';
import searchIcon from '../icons/white_search_icon.svg'
import markerIcon from '../icons/white_marker_icon.svg'
import carpenterIcon from '../icons/carpenter.svg';
import electricianIcon from '../icons/electrician.svg';
import masonIcon from '../icons/mason.svg';
import brickMasonIcon from '../icons/mason.svg';
import personIcon from '../icons/business_person.svg';
import background from '../workers_yellow_transparent.png';

const SectionTitle = styled(Box)`
   font-size:18px;
   padding:16px 0;
   color:#757575;
`;

const PropertyTitle = styled(Box)`
   background:#efefef;
   font-size:14px;
   color:#000;
   font-weight:500;
   padding:8px 16px;
   margin: 0 -16px;
`;

const MoreJobsButton = styled(Box)`
   line-height: 2;
   letter-spacing: 0.35px;
   color: #ffc400;
   margin:0 auto;
`;

const StyledLabel = styled(Label)`
   color:#9b9b9b;
   font-size:16px;
  
`;

const StyledOptions = styled(Options)`
   & > div{
      width: 25%;
      margin: 0;
      box-sizing: border-box;
      border: 0;
   }

   & > div div:nth-child(1){filter:saturate(0)}
   & > div.toggled div:nth-child(1){filter:saturate(1)}
   & > div div:nth-child(2){color:#757575;}


   & [data-value='relevance'] .icon img{position:relative; top:-5px; right:-5px;}
`;

const TransparentField = styled(Flex)`
    border-bottom:2px solid #fff;
    padding: 8px 0;
    position:relative;
  z-index:1;
    & input{border:none; color:#fff; text-align:left;}
    & input::placeholder{color:#fff}
    & input:focus-visible{outline: none !important;}
`;


const TintedBackground = styled(Box)`
    position:relative;
    background-color:#dc8302;
    margin: -16px -16px 0;
    padding: 24px 16px;
`;

const BackgroundImage = styled(Box)`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-image: url(${background});
    background-size: cover;
    filter: saturate(0);
    mix-blend-mode: multiply;
`;

const YellowButton = styled(Box)`
    width: 88px;
  height: 36px;
  padding: 10px 22px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffc600;
  margin:24px auto 0 !important;
  color:#fff;
  position:relative;
  z-index:1;
`;

const RecentSearchList = styled(Box)`
  padding:8px;
`;


const RecentIcon = styled.img`
    width:32px; margin-right:16px;
`;

const RecentLabel = styled(Flex)`
    font-size:16px;
    color:#000;
    border-bottom:1px solid #ccc;
    align-items:center;
    height:45px;
    width:100%;
    
`;

const SearchSuggestions = styled(Box)`
    position:absolute;
    z-index:99;
    top:50px;
    left:0;
    width:100%;
    height:auto;
    background:#fff;
    box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.3), 0 0 24px 0 rgba(0, 0, 0, 0.22);
  border-style: solid;
  border-width: 0.5px;
  border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
  border-image-slice: 1;
    & .suggestion{
        padding:12px;
        align-items:center;
        font-size:16px;
    }

    & img{width:2em; margin-right:12px;}
`;


const dummySearches = [
    {query:"Carpenter", type:"title"},
    {query:"Turner Construction", type:"company"},
    {query:"Electrician", type:"title"},
    {query:"Baine Construction", type:"company"},
    {query:"Mason", type:"title"},
    {query:"Brick Mason", type:"company"},
]

//These icon arrays can be used across the app

const icons = [
    {name:"Carpenter", icon:carpenterIcon},
    {name:"Electrician", icon:electricianIcon},
    {name:"Mason", icon:masonIcon},
    {name:"Brick Mason", icon:brickMasonIcon},
    {name:"Person", icon:personIcon}
];

function Search(props){
    props.setSection("Search");

    const [recentSearches, setRecents] = useState(dummySearches);
    const [suggestedResults, setSuggested] = useState([[],[],null]);

    const addRecent = (recent) =>{

    }

    let history = useHistory();

    const applySearch = ()=>{
        let filter = props.filter;
        let filterValue = document.querySelector("#title-search").value;
        let locationValue = document.querySelector("#location-search").value;
        filter.filter_by.titles = [filterValue];
        filter.filter_by.location = locationValue;
        props.setFilter(filter);
        history.push("/jobs");
    }

    const matchSuggested =  value =>{
        value = value.currentTarget.value;
        if(value.length < 4){
            setSuggested([[],[]]);
        }
        else{
            let matchedJobs = props.jobs.filter(job=>{
                let regex = new RegExp(`(^${value})\\w*.*\\w*`,"gi");
             //   console.log(regex, job.title, regex.test(job.title));
             let searchFilter = regex.test(job.title)
                if(searchFilter){return true}
            });
    
            let matchedUsers = props.users.filter(user=>{
                let regex = new RegExp(`(^${value})\\w*.*\\w*`,"gi");
             //   console.log(regex, job.title, regex.test(job.title));
                let searchFilter = regex.test(user.first_name);
                if(searchFilter){return true}
                   searchFilter = regex.test(user.last_name);
                if(searchFilter){return true}
            });
    
            setSuggested([matchedJobs, matchedUsers, value])
        }
       
    }

    
    useEffect(()=>{
        console.log(suggestedResults)
    });

    const recentSearchItems = recentSearches.map(recent =>{
        let icon = icons.filter(icon=>{
            if(recent.type === "company" && icon.name === "Person"){return true;}
            else if(icon.name === recent.query){return true}
        })[0].icon; 

        return <Flex alignItems="center" style={{marginRight:"-16px", width:"100%"}} onClick={()=>{
            let filterValue = recent.query;
            let filter = props.filter;
            if(recent.type === "title"){

                filter.filter_by.titles = [recent.query];
            }else{
                filter.filter_by.titles = props.filter.filter_by.titles;
            }
           // filter.filter_by.company = recent.type === "company" ? recent.query : props.filter.filter_by.company;
            props.setFilter(filter);
            history.push("/jobs");
        }}><RecentIcon src={icon}/><RecentLabel>{recent.query}</RecentLabel></Flex>
    });

    const matchedJobs = suggestedResults[0].map(result=>{
        let icon = getIcon(result.title);
        return <Flex className="suggestion job-suggestion" onClick={()=>{
 let filter = props.filter;
        let filterValue = result.title;
        filter.filter_by.titles = [filterValue];
        props.setFilter(filter);
        setSuggested([[],[]]);
        history.push("/jobs");
        }}><img src={icon}/> <Box flexGrow="1">{result.title}</Box></Flex>
    });

    const matchedUsers = suggestedResults[1].map(result=>{
        let icon = getIcon("user");
        
        return <Flex className="suggestion user-suggestion" onClick={(e)=>{
        //     let filter = props.filter;
        // let filterValue = result.company;
        // filter.filter_by.titles = [filterValue];
        // props.setFilter(filter);

            setSuggested([[],[]]);
            history.push("/jobs");
        }}><img src={icon}/> <Box flexGrow="1">{result.first_name+" "+result.last_name}</Box></Flex>
    });

    if(suggestedResults[2] && suggestedResults[2].length > 3){
        matchedJobs.unshift(<Flex className="suggestion job-suggestion" backgroundColor="#f7f7f7"><img src={getIcon(suggestedResults[2])}/> <Box flexGrow="1">Search for {suggestedResults[2]} in Job Titles</Box></Flex>)
        matchedUsers.unshift(<Flex className="suggestion job-suggestion" backgroundColor="#f7f7f7"><img src={getIcon("user")}/> <Box flexGrow="1">Search for {suggestedResults[2]} in Person</Box></Flex>)
    }
   

   return <Flex flexDirection="column" justifyContent="space-between" height="100%" padding="0" textAlign="left" onClick={()=>{setSuggested([[],[]]);}}>
      <Box>
      <TintedBackground>
        <BackgroundImage/>
        <TransparentField style={{zIndex:9}}>
        <Input autoComplete="off" id="title-search" placeholder={props.user && props.user.type == "worker" ? "Job Title or Company" : "Job Title or Person"}  onChange={matchSuggested}/>
        <img src={searchIcon}/>
        
        {(suggestedResults[0].length !== 0 || suggestedResults[1].length !== 0) && <SearchSuggestions>
            {matchedJobs}
            {matchedUsers}
        </SearchSuggestions>}

        </TransparentField>
        <TransparentField style={{marginTop:"24px"}}><img src={markerIcon} style={{padding:"0 6px"}}/>
        <Input id="location-search" placeholder={"Current City"}  onChange={(e)=>{
            
        }}/>
        
        </TransparentField>
        <YellowButton onClick={applySearch}>APPLY</YellowButton>
      </TintedBackground>


      <Box>
        <PropertyTitle>Recent Searches</PropertyTitle>
    <RecentSearchList>{recentSearchItems}</RecentSearchList>
      </Box>
      </Box>
   
   </Flex>;
}


export default withRouter(Search);