import React, {useState} from 'react';
import {Flex, Text, Box, LinkAnchor} from 'rebass';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import styled from 'styled-components';

import Subheading from '../components/Subheading';
import Button from '../components/Button';
import Heading from '../components/Heading';
import Input from '../components/Input';
import AppliedJobCard from '../components/AppliedJobCard';
import Modal from './Modal3.js';
import UserPhoto from './UserPhoto.js';
import { ModalProvider } from 'styled-react-modal';

import ContactedIcon from '../icons/contacted_status_icon.svg';
import CompletedIcon from '../icons/completed_status_icon.svg';
import ExpiredIcon from '../icons/expired_status_icon.svg';

const Image = styled(Box)`width:100px; height:100px; margin: 0 auto; background-size: contain; background-repeat: no-repeat; background-position: center;`;

const StyledHeading = styled(Heading)`
    width:100%;
    font-size:24px;
    padding-bottom:0;
    padding-top: 16px;
    &:after{
        width:100%;
        margin:12px 0;
    }
`;

export default function ApplicationsList(props){

    const [changeStatusOpen, changeStatusOpenChange] = useState(false);
    const [currentCard, setCurrentCard] = useState(null);
    
    let filteredJobs = props.applications;
    if(typeof props.applications === "undefined"){return null};

    if(props.filter){
        filteredJobs = props.applications.filter(job=>{
            if(job.title == props.filter || props.filter == "all"){return true}
        });
    }

    const FeedList = props.applications.map((application, index) =>{
        //let cardImage = <img src={SampleLogo}/>;

        let cardImage = application.company ? props.companies.filter(company=>{return company.name === application.company ? true : false})[0].logo : '';

        let job = props.jobs.find(job => job.job_id === application.job_id);

        return(<AppliedJobCard type={props.type} user={props.user} revealModal={changeStatusOpenChange} index={index} {...application} key={index} cardImage={cardImage} setCurrentCard={setCurrentCard} onClick={()=>{}} title={job.title} />)
    })

    const changeStatus = function(index, status){
       
        let applications = props.applications;
        let originalStatus =  applications[index].status;
        applications[index].status = status;
  
        props.updateApplications(applications);
        changeStatusOpenChange(false);

        props.setSnack({id: Math.random(),title: "Changed Status", actionText:"Undo", action:()=>{
            let applications = props.applications;
            applications[index].status = originalStatus;
            props.updateApplications(applications);
            props.setSnack(null)
          }});
    }


    return(
       
    <Flex flexDirection="column" justifyContent="space-between" height="100%" style={{backgroundImage:' linear-gradient(to bottom, rgba(255, 217, 0, 0), #ffd900 4%, #ffc600 8%)', overflow:"hidden"}}>
    <ModalProvider><Modal isOpen={changeStatusOpen} style={{borderRadius:"10px", height:"auto"}}>
    <StyledHeading>Change Status</StyledHeading>

   {currentCard && currentCard.user_id ? <UserPhoto user={currentCard.user} className="user-image" style={{margin:"0 auto", width:"70px", height:"70px"}}/> : currentCard && <Image className="company-image" style={{backgroundImage:"url('"+ props.companies.filter(company=>{if(company.name === currentCard.company){return true}})[0].logo+"')", margin:"0 auto"}}/>}

    <Box fontSize={"20px"}>{currentCard && currentCard.title}</Box>
    <Box fontSize={"16px"} fontWeight={600} padding={"8px 0 16px"}>{currentCard && currentCard.company}</Box>
        <Flex alignItems="center" width="100%" onClick={()=>{changeStatus(window.currentCard, 0)}}>
            <Box><img src={ContactedIcon} style={{marginBottom:"8px"}}/></Box>
            <Box style={{textTransform:"uppercase", color: "#f6bf00", fontSize:"10px", paddingLeft:"16px"}}>Contacted</Box>
        </Flex>
        <Flex alignItems="center" width="100%" onClick={()=>{changeStatus(window.currentCard, 1)}}>
            <Box><img src={CompletedIcon} style={{marginBottom:"8px"}}/></Box>
            <Box style={{textTransform:"uppercase", color: "#3c910e", fontSize:"10px", paddingLeft:"16px"}}>Completed</Box>
        </Flex>
        <Flex alignItems="center" width="100%" onClick={()=>{changeStatus(window.currentCard, 2)}}>
            <Box><img src={ExpiredIcon} style={{marginBottom:"8px"}}/></Box>
            <Box style={{textTransform:"uppercase", color: "#b61b0b", fontSize:"10px", paddingLeft:"16px"}}>Rejected</Box>
        </Flex>
    </Modal></ModalProvider>


        <Box  id="feed-box" style={{padding:"0", overflow:"auto", background:"#ffc600"}}>
            {FeedList}
        </Box>
         
     
      </Flex>
      
      )
}
