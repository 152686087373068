import React from 'react';
import Modal from './Modal';
import {useState, useEffect} from 'react';
import Button from './Button.js';
import styled from 'styled-components';
import Subheading from './Subheading.js';
import {Link} from 'react-router-dom';
import {Label, Input} from '@rebass/forms';
import addReaction from '../network/addReaction.js';

const StyledModal = styled(Modal)`
  width: 20rem;
  height: 20rem;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 7px 27px 0 #d3c07f;
  padding:1rem;
  box-sizing:border-box;
  text-align:left;
`;

const YellowButton = styled.div`
    font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.38);
`;

 
export default function FancyModalButton(props) {
  const [isOpen, setIsOpen] = useState(true);


  useEffect(()=>{

      // if(props.isOpen && isOpen == false){
      //   setIsOpen(false);
      // }else if(props.isOpen && isOpen == true){
      //   setIsOpen(true);
      // }
  });

  //if(props.isOpen){setIsOpen(true)}
 
  const  toggleModal = (e)=> {
    setIsOpen(!isOpen);
  }

 
    return (
        <Modal isOpen={isOpen} className="comment-modal">
            <div>Comment</div>
            <div>{props.post_id}</div>
            <Input multiline={true}/>
            <YellowButton>CANCEL</YellowButton><YellowButton onClick={()=>{
              addReaction("comment");
            }}>SUBMIT</YellowButton>
        </Modal>
    )
  
}