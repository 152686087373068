import React from 'react';
import Modal from 'styled-react-modal';
import {useState, useEffect} from 'react';
import Button from './Button.js';
import styled from 'styled-components';
import Subheading from './Subheading.js';
import {Link} from 'react-router-dom';
const StyledModal = Modal.styled`
  width: 20rem;
  height: 20rem;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 7px 27px 0 #d3c07f;
  padding:1rem;
  box-sizing:border-box;
  text-align:left;
`;

const CancelButton = styled.div`
    font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.38);
`;

 
export default function FancyModalButton(props) {
  const [isOpen, setIsOpen] = useState(true);


  useEffect(()=>{

      // if(props.isOpen && isOpen == false){
      //   setIsOpen(false);
      // }else if(props.isOpen && isOpen == true){
      //   setIsOpen(true);
      // }
  });

  //if(props.isOpen){setIsOpen(true)}
 
  const  toggleModal = (e)=> {
    setIsOpen(!isOpen);
  }

 
    return (
        <StyledModal
          isOpen={props.isOpen && isOpen}
          onBackgroundClick={props.onBackgroundClick || toggleModal}
          onEscapeKeydown={toggleModal}
          backgroundProps={{style:{backgroundColor:"none", width:"100%", height:"100%"}}}
          className={props.className}
          style={props.style}
          afterClose={props.afterClose}
          >
         

        {props.children}

        </StyledModal>
    )
  
}