import React from 'react';

import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import Score from '../Score.js';
import UserPhoto from '../UserPhoto';

const Author = styled(Box)`font-size:16px; margin: 8px 0 !important;`;
const Content = styled(Box)`font-size:14px;
display:-webkit-box;
-webkit-line-clamp: 4;
overflow:hidden;
line-height:1.2;
-webkit-box-orient: vertical;
`;


const ReadMoreButton = styled.div`
     font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: #ffc600;
  padding:16px 0;
`;

export default function Review(props){

    const toggleMore = (e)=>{
        
    }

    return (<Flex {...props}>
        <Box minWidth={"fit-content"} padding={"0 8px"}><UserPhoto user={props.user} style={{width:"65px", height:"65px"}}/></Box>
        <Box flexGrow={1} padding={"0 16px;"}>
            <Flex alignItems={"center"} color={"#4a4a4a"} fontSize={"14px"} padding={"4px 0"}><Score score={props.score} style={{marginRight:"16px"}}/>{props.date}</Flex>
            <Author>{props.user.username}</Author>
            <Content>{props.content || "John Doe"}</Content>
            <ReadMoreButton onClick={e=>{e.currentTarget.textContent = "-"; e.currentTarget.previousElementSibling.style.display = "block"}}>{"Read more"}</ReadMoreButton>
        </Box>
    </Flex>)
}

