import React, {createElement, useEffect} from 'react';
import {Flex, Box} from 'rebass';
import styled from 'styled-components';
import yellowLogo from '../hh_logo_yellow.svg'
import blackLogo from '../hh_logo.svg'


const LoadingScreen = styled(Flex)`
    background:#fff;
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index:2;
    justify-content: center;
    flex-direction:column;
    align-items:center;
    transition:all 0.3s ease-in-out;
    opacity:1;

    &.hidden{opacity:0; pointer-events:none;}
`;

const LogoAnimation = styled(Box)`
    position:relative;
    width:65px;
    height:100px;
    
    & img{position:absolute; width:100%; top:0; left:0; width:65px; height:65px; animation: rotateLogo 2s linear 0s infinite forwards; transform:rotateY(0deg);}
    & .black-logo{height:64px; transform:rotateY(90deg); animation: flipAnimation2 2s linear 2s infinite forwards; display:none}

    @keyframes flipAnimation{
        0% {transform:rotateY(0) scale(1); opacity:1}
        25%{transform:rotateY(90deg) scale(2);  opacity:1}
        50% {transform:rotateY(180deg)  scale(1);  opacity:1}
        75% {transform:rotateY(270deg)  scale(2);  opacity:1}
        100% {transform:rotateY(360deg)  scale(1);  opacity:1}
    }

    @keyframes flipAnimation2{
        0% {transform:rotateY(90deg) scale(2);  opacity:0}
        25%{transform:rotateY(180deg) scale(1); opacity:1;}
        50% {transform:rotateY(270deg)  scale(2);  opacity:0}
        75% {transform:rotateY(360deg)  scale(1);  opacity:1}
        100% {transform:rotateY(450deg)  scale(2);  opacity:0}
    }

    @keyframes rotateLogo{
        from{transform:rotateY(0deg)}
       to{transform:rotateY(360deg)}
    }
`;



export default function Loading(props){
    useEffect(()=>{
        
    }, props.loading);

  

   


    return <LoadingScreen className={!props.loading ? "hidden" : ""}>
        <LogoAnimation>
        <img src={blackLogo} className="black-logo"/>
        <img src={yellowLogo} className="yellow-logo"/>
       
        </LogoAnimation>
        <Box>Loading...</Box>
    </LoadingScreen>

}