import styled from 'styled-components';
import {Box, Flex} from 'rebass';
import {Textarea, Label} from '@rebass/forms';
import mobileFrame from '../images/mobile_frame.png';

export const Header = styled(Flex)`
& .fade-enter {
  opacity: 0;
  z-index: 1;
}

& .fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
`;

export const Notification = styled.div`
      border-radius:50%;
    color:white;
    background:red;
    position:absolute;
    top:8px;
    right:8px;
    width:16px;
    height:16px;
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:1;
`;


export const Image = styled(Box)`width:100px; height:100px; margin: 0 auto; background-size: contain; background-repeat: no-repeat; background-position: center;`;

export const ModalTitle = styled(Box)`font-size:24px; text-align:center;`;

export const Location = styled(Box)`color:#4a4a4a; font-size:14px; font-weight:400; text-align:center;`;

export const StyledTextarea = styled(Textarea)`
    background: #d8d8d8 !important;
    color:#4a4a4a !important;
    font-size:16px;
    border-radius:0;
    border:0 !important;
    border-bottom: 1px solid #ffc600 !important;
    width:100%;
    height:100%;
    min-height:150px;
    font-family: 'Roboto';
    line-height:1.4;
    outline:none;
    resize: none;
`;

export const StyledLabel = styled(Label)`
    color: rgba(0, 0, 0, 0.38);
    padding: 8px;
    background: #d8d8d8;
    margin-bottom:-1px;
    font-size:12px;
`;

export const DeviceFrame = styled.div`
width: 428px;
height: 916px;
background: url('${mobileFrame}') no-repeat center/contain;
z-index: 1;
position:absolute;
top:50%;
left:50%;
transform:translate(-50%,-50%);
pointer-events:none;
`;