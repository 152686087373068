import firebase from 'firebase';

const getComments = (post_id, callback) =>{
      let db = firebase.firestore();
      let docRef = db.collection('comments').where("about", "==", post_id);
      docRef.get().then(result =>{
        let resultArray = [];
        result.forEach(r => {resultArray.push(r.data())})
         callback(resultArray);
      }).catch(error=>{console.error(error)});
}

export default getComments;