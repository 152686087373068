import React from 'react';
import {Flex, Box} from 'rebass';
import Subheading from '../components/Subheading.js';
import styled from 'styled-components';
import UserPhoto from './UserPhoto.js';
import Score from './Score.js';
import sampleBackground from '../images/mars.jpg';

const StyledPhoto = styled.div`
min-height: 200px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #d8d8d8;

`;
export default function(props){
    let companyImage = '';
    let company = props.companies.filter(c=>{return(c.name === props.job.company ? true : false)});
    if(company.length >0){
        companyImage = company[0].image;
        console.log(company)
    }

    return(<Box style={props.style}>
    <StyledPhoto style={{backgroundImage:`url('${companyImage}')`}}>

    </StyledPhoto>
   
    </Box>)
}
