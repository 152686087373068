import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import SampleLogo from '../sample_logo.png';
import LocationIcon from '../icons/location_icon.svg';
import CalendarIcon from '../icons/calendar_icon.svg';
import HeartIcon from '../icons/heart_icon.svg';
import ShareIcon from '../icons/share_icon.svg';

import Button from './Button.js';
import Score from './Score.js';


import ContactedIcon from '../icons/contacted_status_icon.svg';
import CompletedIcon from '../icons/completed_status_icon.svg';
import ExpiredIcon from '../icons/expired_status_icon.svg';
import UserPhoto from './UserPhoto';


const StyledFeedCard = styled(Flex)`
  background:#fff;
`;


const StatusChangeButton = styled(Button)`
    position:relative;
     height:27px;
  border-radius: 18px;
  border-style: solid;
  border-width: 2px;
  border-image-source: linear-gradient(173deg, #ffee00 14%, #ffd900 69%);
  border-image-slice: 1;
  font-size:10px;
  font-weight:500px;
  line-height:1;
 width:100%;
  box-shadow:none;
  z-index:0;

    &:before{
        content:"";
        position:absolute;
        top:0;
        left:0;
        background:#fff;
        width:100%;
        height:100%;
        border-radius:18px;
        z-index:-1;
    }
`;


const StyledUserPhoto = styled(UserPhoto)`
width:80px; height:80px; margin-right:0;
`;



export default function AppliedJobCard(props){

    let statusIcon = ContactedIcon;
    let statusText = "Contacted";
    let labelColor = "#f6bf00";

    let contactText = "APPLY";
  

    if(props.type == "worker"){
      contactText = "CONTACT";
    //  cardImage = <StyledUserPhoto user={props.user}/>
    }

    if(props.status == 2){
        statusIcon = ExpiredIcon;
        statusText = "Expired";
        labelColor = "#d0021b";
    }else if(props.status == 1){
        statusIcon = CompletedIcon;
        statusText = "Completed";
        labelColor = "#47a216";
    }

   

    return (<StyledFeedCard style={{borderBottom:"1px solid #ccc", minHeight:"150px", padding:"16px 8px", justifyContent:"space-between"}}>

  <Flex flexDirection="column" justifyContent="space-between" style={{flex:"0 0 85px"}}>
      <Box><img src={statusIcon} style={{marginBottom:"8px"}}/>
      <Box style={{textTransform:"uppercase", color: labelColor, fontSize:"10px"}}>{statusText}</Box></Box>
      <StatusChangeButton primary onClick={()=>{props.revealModal(true); props.setCurrentCard(props); window.currentCard = props.index;}}>Change Status</StatusChangeButton>
  </Flex>
             <Box style={{textAlign:"left", flex:"0 60%", paddingLeft:"16px", display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"}}>
                 <Box style={{fontSize:"21px"}}>{props.title || (props.level+" "+props.trade)}</Box>
                <Box style={{fontSize:"16px", fontWeight:"bold"}}>{"$"+props.rate+"/h"}</Box>
                 <Box style={{fontSize:"14px", color:"#4a4a4a"}}>{props.company}</Box>
                 <Box style={{fontSize:"14px", color:"#4a4a4a"}}>{props.location}</Box>
                 <Score style={{width:"fit-content"}} score={props.rating}/>
             </Box>
             <Flex style={{textAlign:"right", flex:"0 40%"}} flexDirection="column" justifyContent="space-between">
                 <Box><img src={props.cardImage} style={{width:"100%",maxWidth:"100px"}}/></Box>
                 <Box>
                     {/* <Box style={{color:"#4a4a4a", fontSize:"12px"}}><img src={LocationIcon}/> {props.distance} mi</Box>
                     <Box style={{color:"#4a4a4a", fontSize:"12px"}}><img src={CalendarIcon}/> {props.date}</Box> */}
                 </Box>
             </Flex>
         
    </StyledFeedCard>);
}

