import * as React from 'react';
import styled from 'styled-components';
import {Box, Flex} from 'rebass';
import {Link} from 'react-router-dom';
import Subheading from '../components/Subheading';
import Button from '../components/Button';
import logo from '../logo.svg';
import background from '../workers_yellow_transparent.png';
import {useHistory} from 'react-router-dom'
  // RCE CSS
import 'react-chat-elements/dist/main.css'
import {MessageBox, ChatItem, MessageList } from 'react-chat-elements';
import ChatConversation from './ChatConversation';

const conversations = [
    [ {
        avatar: 'https://facebook.github.io/react/img/logo.svg',
        alt: 'Reactjs',
        title: 'Facebook',
        subtitle: 'What are you doing?',
        date: new Date(),
        unread: 0,
    }, {
        avatar: 'https://facebook.github.io/react/img/logo.svg',
        alt: 'Reactjs',
        title: 'Facebook',
        subtitle: 'What are you doing?',
        date: new Date(),
        unread: 0,
    }, {
        avatar: 'https://facebook.github.io/react/img/logo.svg',
        alt: 'Reactjs',
        title: 'Facebook',
        subtitle: 'What are you doing?',
        date: new Date(),
        unread: 0,
    }, {
        avatar: 'https://facebook.github.io/react/img/logo.svg',
        alt: 'Reactjs',
        title: 'Facebook',
        subtitle: 'What are you doing?',
        date: new Date(),
        unread: 0,
    },]
]

const StyledView = styled(Flex)`
    background: #fff url(${background}) no-repeat bottom /140%;


background-image: ${props => props.secondary ? "linear-gradient(99deg, #b60a0a 12%, #7a0000 90%)" : background};

padding:4px 4px 0px;
box-sizing:border-box;
`;

const _onClick = (e) => {
    console.log(e)
}



export default function Chat({user, conversations, setSection}){
    const [conversation, setConversation] = React.useState(null);
    const messageListRef = React.useRef();

    const sendMessage = (message) => {
        setConversation([...conversation, message])
    }

    const history = useHistory();
    
    
  setSection("Chat");
    return(<StyledView height="100%" flexDirection="column" justifyContent="space-between">
{!conversation && conversations.map(cnv => {return <ChatItem
  avatar={logo}
  alt={'Reactjs'}
  title={'HiringHall'}
  subtitle={'What are you doing?'}
  date={new Date()}
  unread={1}
  onClick={(e) => {console.log(cnv); setConversation(cnv); history.replace('chat/'+cnv.conversation_id)}}
/>})}


{conversation && <ChatConversation user={user} conversation={conversation} sendMessage={sendMessage}/>}

    


</StyledView>)}