import React, {useRef, useState} from 'react';

import styled from 'styled-components';
import { Flex } from 'rebass';


const StyledCard = styled(Flex)`
    border-radius: 11px;
  box-shadow: 0 5px 14px 0 #9a5500, 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0.5px;
  border-image-source: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.02) 95%, rgba(0, 0, 0, 0.04));
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.02) 95%, rgba(0, 0, 0, 0.04));
  background-origin: border-box;
  background-clip: content-box, border-box;
  transition:all .5s ease-in-out;
  overflow:hidden;
  transform: translateX(0);
  opacity:1;
  max-height:400px;

  & a{color:inherit; text-decoration:none;}

  &.hidden{
    max-height:0;
    border:0;
    transform:translateX(-120%);
    margin:0;
  }
`;

// const CardAction = styled(Box)`
// padding: 8px;
// opacity: 1;

// border-radius: 50%;
// display: flex;
// align-items: center;

// border: 0px solid #ccc;
// filter:saturate(1);

// &.toggled{
//   opacity: 1;
//   filter:saturate(0);
// }
// `;

export default function Card(props){

  const [hidden, setHidden] = useState(false);
  const ref = useRef();

  if(hidden){return null};

  return (<StyledCard ref={ref} className="card" flexDirection="column" style={{marginBottom:"16px"}} {... props}>
                {props.children}
             </StyledCard>);
}

