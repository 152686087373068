import React, {useState} from 'react';
import {Box} from 'rebass';
import styled from 'styled-components';

import UserIcon from '../icons/user_icon.svg'
import EliteBadge from '../elite_badge.svg'


const ProfilePhoto = styled(Box)`
    position:relative;
    width:116px;
    height:116px;
    border-radius:50%;
    margin:0;
    background-size:cover;
    background-position:center;
`;

const CertifiedBadge = styled(Box)`
    background-image:url(${EliteBadge});
    width: 40%;
    height: 40%;
    max-width:48px;
    max-height:48px;
    position:absolute;
    top:0;
    left:0;
    top: 0;
    background-size:contain;
    background-repeat:no-repeat;
`;



export default function UserPhoto(props){
    if(typeof props.user == "undefined"){return <ProfilePhoto {... props}/>}
    let badge = null;
    if(props.user && props.user.membership &&  props.user.membership == "elite"){
        badge = <CertifiedBadge className="badge"/>
    }

    return(
        <ProfilePhoto  style={{ ...props.style, backgroundImage:`url(${(props.user && props.user.image) ? props.user.image : UserIcon})`}} className={props.className}>{badge}</ProfilePhoto>
        );
}