import React, {useState} from 'react';
import {Flex, Box} from 'rebass';
import Subheading from '../components/Subheading.js';
import styled from 'styled-components';
import FeedList from '../components/FeedList';

import Score from '../components/Score.js';
import UserHeader from '../components/UserHeader.js';
import SamplePhoto from '../john_doe.png';

import {Link, Switch, Redirect, Route, useRouteMatch, useHistory} from 'react-router-dom';
import Button from '../components/Button';

import ApplicationsList from '../components/ApplicationsList';
import UserList from '../components/UserList';

import {CalendarIcon, LocationIcon, CarpenterIcon, getIcon} from '../components/Icons'

const TabbedNavButton = styled(Box)`
    position: relative;
    font-size: 14px;
    color: #000;
   
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
    & a{
        color:#000;
        text-decoration: none;
        width:100%;
        height:100%;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    &.toggled:after{
        content:"";
        height:5px; 
        width:100%;
        background:#000;
        position:absolute;
        bottom:0;
        left:0;
    }
`;



const MyJobTitle = styled(Box)`
    font-size:21px;
`;

const MyJobRate = styled(Box)`
    font-size:18px;
    font-weight: 600;
`;
const MyJobId = styled(Box)`
    font-size:16px;
`;

const MyJobLocation = styled(Box)`
    color: #4a4a4a;
    font-size:14px;
`;

const MyJobIcon = styled(Flex)`
    color: #4a4a4a;
    font-size:14px;
    width:76px;
    padding:16px;
    align-items:center;
    justify-content:center;
`;

export default function Me(props){
    let {path, url} = useRouteMatch();
    let history = useHistory();

    if(props.user === null){return <Redirect to="/"/>}

    const UpgradeBanner = (props.user && props.user.type === "worker" && props.user.membership !== "elite") ? <Box style={{color:"#f6bf00", fontSize:"14px", background:"black", padding:"16px", textTransform:"uppercase"}}><span style={{fontSize:"24px"}}>{props.dailyApplications}</span> Applications Remaining* <Link to="/register/become-elite" style={{textDecoration:"underline", color:"inherit"}}>JOIN ELITE</Link></Box> : null;

    props.setSection("Me");



    let jobsToggled = (path == "/me/jobs") ? "toggled" : null;
    let savedToggled = (path == "/me/saved") ? "toggled" : null;

   let favoriteJobs = props.jobs.filter(job=>{return props.favorites.includes(job.job_id)});
   if(props.user.type === "employer"){
    favoriteJobs = props.users.filter(user=>{return props.favorites.includes(user.user_id)});
   }

   const myJobsList = props.jobs.filter(job =>{if(props.favorites.includes(job.job_id)){return true} return job.posted_by === props.user.username ? true : false});



   const myJobs = myJobsList.map((job, index)=>{
    let JobIcon = getIcon(job.title);

    //Why is this duplicate of the feed bacr dhere?
       return(
           <Flex key={index} onClick={()=>{history.push("/job/"+job.job_id)}} style={{borderBottom:"1px solid #c8c7cc", paddingBottom:"16px"}}>
               <Flex><MyJobIcon><img src={JobIcon}/></MyJobIcon></Flex>
               <Box flexGrow="1" textAlign="left" padding= "8px">
                <Box flexGrow="1">
                    <MyJobTitle>{job.title}</MyJobTitle>
                    <MyJobRate>{"$"+job.rate+"/hr"}</MyJobRate>
                    <MyJobId>{"Job #"+job.job_id}</MyJobId>
                </Box>
                   <Flex alignItems={"flex-end"}><MyJobLocation flexGrow="1">{job.location}</MyJobLocation><Box>   <Flex style={{color:"#4a4a4a", fontSize:"12px", alignItems:"center", justifyContent:"flex-end", marginBottom:"4px"}}><img src={LocationIcon} style={{width:"12px", marginRight:"8px"}}/> {props.distance ? (props.distance +"mi") : "Not available"}</Flex>
                     {props.type == "user" ? null : <Flex style={{color:"#4a4a4a", fontSize:"12px", alignItems:"center", justifyContent:"flex-end"}}><img src={CalendarIcon} style={{width:"12px", marginRight:"8px"}}/> {props.date || "Not available"}</Flex>}</Box></Flex>
               </Box>
           </Flex>
       );
   });

    return(<Flex flexDirection="column">
        <Flex  flexDirection="column" justifyContent="space-around" style={{backgroundImage:"linear-gradient(127deg, #ffee00 12%, #f6bf00 76%)", borderBottom:"solid 1px #666"}}>
        <UserHeader user={props.user} style={{padding:"24px 0 8px"}}></UserHeader>
        <Flex justifyContent="space-around" alignItems="center" style={{color:"black", height:"45px"}}>
            <TabbedNavButton className={(window.location.pathname == "/me/jobs") ? "toggled" : null}><Link to={`${path}/jobs`}>My Jobs</Link></TabbedNavButton>
            {props.user && props.user.type == "employer" ? (<TabbedNavButton className={(window.location.pathname == "/me/contacts") ? "toggled" : null}><Link to={`${path}/contacts`}>My Contacts</Link></TabbedNavButton>) : null}
            <TabbedNavButton className={(window.location.pathname == "/me/saved" || window.location.pathname == "/me") ? "toggled" : null}><Link to={`${path}/saved`}>Saved</Link></TabbedNavButton>
        </Flex>
        {UpgradeBanner}
        </Flex>
     
  

        
            <Switch>
            <Route path="/me/applications">
                 <ApplicationsList companies={props.companies} type="worker" applications={props.applications} user={props.user}  updateApplications={props.updateApplications} jobs={props.jobs}/>
            </Route>

            <Route path="/me/contacts">
                 <UserList companies={props.companies} type="worker" applications={props.applications} user={props.user}  updateApplications={props.updateApplications} jobs={props.jobs}/>
            </Route>

            <Route path="/me/jobs">
                {props.user && props.user.type === "worker" && <ApplicationsList  companies={props.companies} applications={props.applications} jobs={props.jobs} updateApplications={props.updateApplications} user={props.user}/>}

                    {props.user && props.user.type === "employer" && <>{myJobs}<Button primary style={{margin:"32px 16px 16px", width:"auto", position:"sticky", bottom:"16px"}}><Link to="/jobs/new">Post New Job</Link></Button></>}
            </Route>

                <Route path="/me/saved">
                    {/*Cheap way to show two lists conditionally*/}
                     {props.user && props.user.type === "worker" && <FeedList type={"worker"} jobs={favoriteJobs} companies={props.companies} addApplication={props.addApplication} contact={props.contact} user={props.user} saveJob={props.saveJob} unSaveJob={props.unSaveJob} favorites={props.favorites}  removeHidden={props.removeHidden} addHidden={props.addHidden}  setSnack={props.setSnack}  addModal={props.addModal}/>}
                     
                     {props.user && props.user.type === "employer" && <FeedList type={"worker"} jobs={favoriteJobs} companies={props.companies} addApplication={props.addApplication} contact={props.contact} user={props.user} saveJob={props.saveJob} unSaveJob={props.unSaveJob} favorites={props.favorites} removeHidden={props.removeHidden} addHidden={props.addHidden}  setSnack={props.setSnack}  addModal={props.addModal}/>}
                </Route>


            </Switch>
    </Flex>);
}