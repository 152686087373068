import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  width:100%;
  border:none; border-bottom:1px solid rgba(0, 0, 0, 0.12); padding:12px;
  color: rgba(0, 0, 0, 0.33);
  color:#000;
  font-size:16px;
  margin:0 0 1rem;
  box-sizing:border-box;

  &:focus{
    outline: none;
    border-color: ${props=> props.theme.lightYellow}
  }
`;

export default function(props) {
  
  return (<StyledInput {...props} autoComplete={"off"}>{props.children}</StyledInput>);
}



