
import firebase from 'firebase';

const addReaction = (post_id, callback, type) =>{
      let db = firebase.firestore();
      let docRef = db.collection('posts').doc(post_id.toString());
      
      docRef.update({reactions: firebase.firestore.FieldValue.arrayUnion({
         type:"comment", 
         message: "Hello World", 
         post_id: post_id, 
         user_id: firebase.auth().currentUser.uid,
        })},{merge: true}).then(docRef=>{

         let userRef = db.collection('users').doc(firebase.auth().currentUser.uid);
       
         userRef.update({reactions: firebase.firestore.FieldValue.arrayUnion({ type:"comment", 
         message: "Hello World",  post_id: post_id})},{merge: true}).then((result)=>{

               if(callback){
                  callback({ type:"comment", 
                  message: "Hello World", },result);
               }

         }).catch(error=>{});
      }).catch(error=>{});
}

export default addReaction;