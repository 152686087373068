import React from 'react';
import styled , {css} from 'styled-components';

const StyledHeading = styled.h3`
  text-align:left;
  font-family: Roboto;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  color: #000000;
`;

export default function(props) {
  

  return (<StyledHeading {... props}/>);
}



