import React, {useState, useEffect} from 'react';
import {Flex, Text, Box, LinkAnchor} from 'rebass';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";

import Button from '../components/Button';
import Heading from '../components/Heading';
import Input from '../components/Input';
import FeedCard from '../components/FeedCard';
import AdCard from '../components/AdCard';

import calculateDistance from '../utilities/calculateDistance';


export default function FeedList(props){

    const[userLocation, setUserLocation] = useState(null);
    const[locationEnabled, setLocationEnabled] = useState(true);

    const LocateUser = ()=>{
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position=>{
            setUserLocation({lat:position.coords.latitude, lng:position.coords.longitude})
          });
        }
      }

    useEffect(()=>{
        if(userLocation === null && locationEnabled){LocateUser();}
    });

    let filteredJobs = props.jobs;

    if(props.filter){
        filteredJobs = props.jobs.filter(job=>{
            if(job.title == props.filter || props.filter == "all"){return true}
        });
    }

    let cardType = null

    let FeedList = filteredJobs.map((job, index) =>{

        if(job.type == "worker" || job.type == "user"){
            cardType = "user";
        }   

        let cardImage = null;

        let company = props.companies.filter(c=>{return(c.name == job.company ? true : false)});
        if(company.length >0){
          cardImage = company[0].logo;
        }

        let favoriteStatus = false;
        if((job.job_id && props.favorites && props.favorites.includes(job.job_id)) || (job.user_id && props.favorites && props.favorites.includes(job.user_id))){
            favoriteStatus = true;
        }

        let distance = null;
        if(userLocation){distance = (calculateDistance(userLocation.lat,userLocation.lng, parseFloat(job.lat), parseFloat(job.lng))*0.621371).toFixed(1);}

        return(<FeedCard key={Math.random()} {... job} distance={distance} saveJob={props.saveJob} unSaveJob={props.unSaveJob} addApplication={props.addApplication}  type={cardType} cardImage={cardImage} contact={props.contact} favoriteStatus={favoriteStatus} removeHidden={props.removeHidden} addHidden={props.addHidden}  setSnack={props.setSnack}  addModal={props.addModal}/>);
    });

    for(let i=0;i<filteredJobs.length;i++){
        if(i % 3 == 0){
            filteredJobs.splice(i,0,<AdCard key={Math.random()}/>)
        }
    }

    if(filteredJobs.length > 3){
        filteredJobs.push(<AdCard key={Math.random()}/>)
    }


    return(
       
    <Flex key={Math.random()} className="feed-list" flexDirection="column" justifyContent="space-between" height="100%" style={{backgroundImage:' linear-gradient(to bottom, rgba(255, 217, 0, 0), #ffd900 4%, #ffc600 8%)', backgroundColor:"#ffc600", overflow:"hidden"}} {... props}>

        <Box  id="feed-box" style={{padding:"16px", overflow:"auto"}}>
            {FeedList}
        </Box>
         
     
      </Flex>
      
      )
}
