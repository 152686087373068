import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Flex, Text, Box, LinkAnchor} from 'rebass';
import {Label, Select, Checkbox} from '@rebass/forms';
import {
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  useHistory,
  Redirect
} from "react-router-dom";

import Button from '../../components/Button';
import Password from '../../components/Password';
import Heading from '../../components/Heading';
import Subheading from '../../components/Subheading';
import Input from '../../components/Input';
import Pagination from '../../components/Pagination'
import Camera from '../../components/Camera';

import loadData from '../../views/loadData.js';


import Confirm from '../../components/Confirm.js';
import RegularModal from '../../components/Modal3.js';
import Modal from '../../components/Modal2.js';
import SkillIcon from '../../icons/info.svg';
import profesionalImage from '../../employee_image.png';
import UserPhoto from '../../components/UserPhoto';
import SingleDigitInput from '../../components/SingleDigitInput';

import employerImage from '../../employer_image.png';
import sampleImage from '../../john_doe.png';
import { ModalProvider , BaseModalBackground} from 'styled-react-modal';
import elite_image from '../../elite_badge.svg';
import hh_logo_dark from '../../hh_icon_black@2x.png';
import hh_logo_yellow from '../../hh_logo_yellow.svg';
import workers_image from '../../workers_yellow_transparent.png';
import {rightChevron, BlueLocator, Description as DescriptionIcon, Skills as SkillsIcon, CertificatesIcon} from '../../components/Icons';
import { filter } from 'lodash';
import firebase from 'firebase';
//import {RecaptchaVerifier} from 'firebase/auth';
//import {getAuth, createUserWithEmailAndPassword} from 'firebase';

import findAddress from './findAddress.js';

const skillLevel = "master";
const awardIcon = null;
const infoIcon = null;


const SpecialModalBackground = styled(BaseModalBackground)`
  background:none;
`;

const SecurityDisclaimer = styled(Box)`
  background:none;
`;



const EliteBadge = styled.div`
  background-image:url('${elite_image}');
  background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100px;
    height: 120px;
    margin: 0 auto;
`;

const Logo = styled.img`
  width:70px;
  margin:16px auto;

`;

const EliteInfoModal = styled(RegularModal)`
 background:#111 url(${workers_image}) no-repeat center/cover;
 padding:16px;
 height:auto;
`;

const WhiteHeading = styled(Box)`
  color:#fff;
  text-transform:uppercase;
  font-size:18px;
`;

const StyledList = styled.ul`
  & li{
    color: #f6bf00;
    font-size:14px;
    padding:8px 0;
  }
`;

const StyledTextAndOptions = styled(Flex)`
  width: 100%;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.12);
    padding: 12px;
    color: rgba(0,0,0,0.33);
    color: #000;
    font-size: 16px;
    margin: 0 0 1rem;
    box-sizing: border-box;
    position:relative;

    & img{position:absolute; right:0; top:50%; transform:translateY(calc(-50% - 0.5rem))}
`;


const SectionTitle = styled(Flex)`
align-items:center;
     margin: 39px 2px 17px 10px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  color: #000;
  text-transform:uppercase;
    display:flex;
    align-items:center;
  & img{width:1em; margin-right:1em;}
`;

const Steps = [
    {index: 0,
      path:"/register",
    component:"Step 1",    
    },
    {path:"/register/email-password",
    component:"Step 2",    
    },
    {path:"/register/phone-number",
    component:"Step 3",    
    },
    {path:"/register/phone-confirmation",
    component:"Step 4",    
    },
    {path:"/register/congrats",
    component:"Step 5",    
    },
    {path:"/register/become-elite",
    component:"Step 5",    
    },
    {path:"/register/full-name",
    component:"Step 5",    
    },
    {path:"/register/home-address",
    component:"Step 5",    
    },
    {path:"/register/ssn",
    component:"Step 5",    
    },
    {path:"/register/profile-picture",
    component:"Step 5",    
    },
];

const GrayLink = styled.div`
    font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.38);
`;

const ContinueButton = styled.div`
  color:#f6bf00;
  font-size:14px;
  box-sizing:border-box;
  width:100%;
  padding:16px;
  text-transform:uppercase;
  text-align:right;
`;




const StyledSelect = styled.select`
border: 0;
font-size: 16px;
padding: 12px 0;
margin-right: -14px;
flex-grow:1;
`;

export default function Registration(props){

    props.setSection("Register");

    const[nextIndex, setPage] = useState(0);

    const[userImageAvailable, setUserImageAvailable] = useState(false);
    const[registrationSteps, setStepCount] = useState(4);
    const[currentStep, setCurrentStep] = useState(0);
    const[eliteModalOpen, setEliteModalOpen] = useState(false);
    const[finishProfileOpen, setFinishModalOpen] = useState(false);
    const[fieldsValidated, setFielsValidated] = useState(false);
    
    const[location, setLocation] = useState(null);

    //const[filter, setFilter] = useState("all");
    let setFilter = props.setFilter;

    let { path, url } = useRouteMatch();
   
    let { step } = useParams();

    let toggleModal = props.toggleModal;


    useEffect(()=>{
      
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log(response);
       //   onSignInSubmit();
        }
      });

      let currentStep = Steps.find(step=> step.path === window.location.pathname);
      if(currentStep){
        let currentIndex = Steps.indexOf(currentStep);

      if(currentIndex !== currentStep){
        setCurrentStep(currentIndex);
      }
      }


      
    });

    const createUser = (user, callback) =>{
      console.log("Creating user with object", user);
      delete user.password;
      delete user.password_confirmation;
     

      if(firebase.auth().currentUser === null){
        return;
      }else{
        user.uid = firebase.auth().currentUser.uid;
        props.setUser(user);
      }

      

      let db = firebase.firestore();
    console.log(db.collection("users"));
      db.collection("users").doc(user.uid).set({...user},{merge:true})
      .then((docRef) => {
        console.log("Document written with ID: ", docRef);
        if(callback){
          callback(docRef.data());
        }
      })
      .catch(error=>{
        console.log("Error");
        props.addModal({title:"User Creation Failed", content:"", type:"alert"})
      });
    };

    const updateUser = (user, callback) =>{
       if(firebase.auth().currentUser === null){
        return;
      }else{
        user.uid = firebase.auth().currentUser.uid;
        delete user.password;
      }
      let db = firebase.firestore();
      db.collection("users").doc(user.uid).set({...user},{merge:true})
      .then((docRef) => {
        console.log("Document written with ID: ", docRef);
        if(callback){
          callback(docRef);
        }
      })
      .catch(error=>{
        console.log("Error");
        props.addModal({title:"User Update", content:"", type:"alert"})
      });
    };

    const signInWithPhone = (phone) =>{
      if(window.recaptchaVerifier){

        return firebase.auth().signInWithPhoneNumber(phone, window.recaptchaVerifier);
        
        firebase.auth().signInWithPhoneNumber(phone, window.recaptchaVerifier).then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          confirmationResult.then(credential=>{
            addPhoneToAccount(credential);
            //addPhoneToAccount(credential)
            console.log(credential)
          })
         
        }).catch(error=>{
          console.log("Error");
          props.addModal({title:"Failed to send SMS", content:"", type:"alert"})
        });
      }
    }

    const addPhoneToAccount = (phoneCredential) =>{
      return firebase.auth().currentUser.linkWithCredential(phoneCredential);
    };

    const registerNewUser = (user, callback) =>{
      let registerUser = firebase.auth().createUserWithEmailAndPassword(user.email, user.password);
      if(callback){
        registerUser.then(result=>{
          callback(result);
          let user = {...props.user};
          delete user.password;
          props.setUser(user.password)
        }).catch(error=>{
        props.addModal(<Confirm title="Failed to register user." type="alert">{error.error ? error.error.message : error.message}</Confirm>);
        });
        return;
      }
      return registerUser;
    }



    let history = useHistory();

    //if(props.user === null || props.user.uid){return <Redirect to="/"/>}


    return(
      <><ModalProvider backgroundComponent={SpecialModalBackground}>
        
      <Modal  key={Math.random()} isOpen={finishProfileOpen} backgroundProps={{style:{backgroundColor:"none", width:"100%", height:"100%"}}} afterClose={()=>{setFinishModalOpen(false);}}></Modal>
      
      
      </ModalProvider>

     
      
    
      
    <Flex flexDirection="column" justifyContent="space-between" height="100%" style={{position:"relative", padding:"32px 16px"}}>

    <Box id="recaptcha-container"/>

        {!["/register/congrats"].includes(window.location.pathname ) ?  <Pagination style={["/register", "/register/guest"].includes(window.location.pathname) ? {top:"84px", left:"16px", position:"absolute"} : {}} key="pagination" pages={["/register", "/register/guest"].includes(window.location.pathname) ? 0 : registrationSteps} current={currentStep}/> : null}
    
          <Switch>


            <Route exact path="/register" key="regular-registration">
            
            <Box style={{backgroundImage:"radial-gradient(circle at 50% 27%, #fff6a6, #f5d500 38%, #deb900 70%)", position:"absolute", top:0, left:0, width:"100%", height:"100%", zIndex:"-1"}}/>
            <Box width={1} style={{background:"#fff",borderRadius:"16px", textAlign:"center", height:"100%", padding:"16px", display:"flex", flexDirection:"column", justifyContent:"space-evenly"}}>
            <Subheading textAlign="center" style={{textAlign:"center", fontWeight:"bold"}}>Register</Subheading>
            <Box flexGrow="1">
            <Box style={{padding:"32px 0", height:"50%", boxSizing:"border-box"}}><Link to="/register/email-password"  onClick={e=>{
                   let user = {type:"employer", membership:"standard"};
                   props.setUser(user);
                }}><img src={employerImage}/><Button knockedOut style={{borderColor:"#FFD900"}}><Subheading  style={{textAlign:"center", margin:"8px 0"}}>I'm an Employer</Subheading></Button></Link></Box>
                <Box style={{padding:"32px 0", height:"50%", boxSizing:"border-box"}}><Link to="/register/email-password" onClick={e=>{
                  let user = {type:"worker", membership:"standard"};
                  props.setUser(user);
                }}><img src={profesionalImage}/><Button knockedOut  style={{borderColor:"#FFD900"}} ><Subheading  style={{textAlign:"center", margin:"8px 0"}}>I'm a Profesional</Subheading></Button></Link></Box>
                
                </Box>
            </Box>
            
            </Route>

            <Route path="/register/guest" key="guest-registration">
            
            <Box style={{backgroundImage:"radial-gradient(circle at 50% 27%, #fff6a6, #f5d500 38%, #deb900 70%)", position:"absolute", top:0, left:0, width:"100%", height:"100%", zIndex:"-1"}}/>
            <Box width={1} style={{background:"#fff",borderRadius:"16px", textAlign:"center", height:"100%", padding:"16px", display:"flex", flexDirection:"column", justifyContent:"space-evenly"}}>
            <Subheading textAlign="center" style={{textAlign:"center", fontWeight:"bold"}}>You're a:</Subheading>
            <Box flexGrow="1">
                
                <Box style={{padding:"32px 0", height:"50%", boxSizing:"border-box"}} onClick={e=>{
                   let user = {...props.guestUser, type:"employer", membership:"standard"};
                  props.setUser(user);
                }}><Link to="/jobs" ><img src={employerImage}/><Button knockedOut style={{borderColor:"#FFD900"}} ><Subheading  style={{textAlign:"center", margin:"8px 0"}}>I'm an Employer</Subheading></Button></Link></Box>
                <Box style={{padding:"32px 0", height:"50%", boxSizing:"border-box"}} onClick={e=>{
                  let user = {...props.guestUser, type:"worker", membership:"elite"};
                  props.setUser(user);
                }}><Link to="/jobs" ><img src={profesionalImage}/><Button knockedOut  style={{borderColor:"#FFD900"}} ><Subheading  style={{textAlign:"center", margin:"8px 0"}}>I'm a Profesional</Subheading></Button></Link></Box>
                </Box>
            </Box>
            
            </Route>

            

            <Route path="/register/email-password">
            <Box width={1}>
            <Heading primary>Enter your email 
address and password </Heading>
</Box>
<Box>
<Box width={1}><Input autoComplete="off" placeholder="Email address" value={props.user ? props.user.email : null} pattern="(\w\.?)+@[\w\.-]+\.\w{2,}" onChange={e=>{
                  props.setUser({...props.user, email: e.currentTarget.value});
                }}/></Box>
<Box width={1}>

<Password key="password-field" placeholder="Password"  onChange={e=>{props.setUser({...props.user, password: e.currentTarget.value});}}/>


                
                </Box>
<Box width={1}><Password key="re-enter-password-field" placeholder="Re-enter Password"  onChange={e=>{props.setUser({...props.user, password_confirmation: e.currentTarget.value});}}/></Box>
                </Box>

            <Button primary onClick={()=>{
                registerNewUser(props.user).then(credential =>{
                  console.log("Created new user:" ,credential,props.user);
                 
                 // updateUser({...props.user, uid: firebase.auth().currentUser.uid});
                  console.log("Creating new user entry");
                  createUser({...props.user, uid: credential.uid});
                  props.setUser({ ...props.user, uid: credential.uid});
                  history.push(`${path}/phone-number`);
                }).catch(error=>{
              
          props.addModal(<Confirm isOpen={true} key={Math.random()} title="Failed to register user" type="alert">{error.error ? error.error.message : error.message}</Confirm>);
          });
        }}>Next</Button> 
            </Route>

            <Route path="/register/phone-number">
            <Box width={1}><Heading primary>What's your phone number?</Heading></Box>
<Flex width={"fit-content"} alignItems={"center"} fontSize={"32px"} margin={"0 auto"}>+1

<Input autoComplete="off" type="tel" placeholder="Phone number" value={props.user ? props.user.phone : ""} style={{letterSpacing:"3px", fontSize:"inherit", position:"relative", top:"2px", padding:"0", margin:"0 0 0 16px", width:"236px"}} onChange={e=>{
                 
                  let value = e.currentTarget.value.split("-").join("");

                  if(value.split("+1").length > 1){
                    value = value.split("+1")[1];
                  }

                  if(value.length > 3){
                    value = value.slice(0,3)+"-"+value.slice(3);
                  }

                   if(value.length > 7){
                    value = value.slice(0,7)+"-"+value.slice(7);
                  }

                  //e.currentTarget.value = value;
                  props.setUser({...props.user, phone: value});
                  
                }}/></Flex>

            <Button primary onClick={()=>{
             if(props.user.phone){
        
               props.setLoading(true);
              firebase.auth().currentUser.linkWithPhoneNumber("+1"+props.user.phone, window.recaptchaVerifier).then(confirmationResult=>{
                window.confirmationResult = confirmationResult;
                props.setLoading(false);

                console.log("Updating new user entry");
                updateUser({...props.user, two_factor: true});

                history.push(`${path}/phone-confirmation`);
              }).catch(error=>{
                console.log("Error");
                props.setLoading(false);
                 props.addModal(<Confirm isOpen={true} title="Verification failed.">
                   {error.error ? error.error.message : error.message}
                 </Confirm>)
              });
             }
              }}>Verify</Button> 
            </Route>

            
            <Route path="/register/phone-confirmation" render={()=>{
              window.document.querySelector(".confirmation-input1");
            }}>
              <Box width={1}><Heading primary>Enter the 6-digit code sent to: <br/>{props.user ? props.user.phone : null}</Heading></Box>
<Box width={1}>
                <Flex justifyContent="space-between">
                <Input style={{margin:"0 8px", textAlign:"center", fontSize:"28px", color:"#666", outline:"none"}} placeholder="" key="confirmation-input1" className="confirmation-input1" onChange={e=>{
                  if(e.currentTarget.value === ""){}else{
                    e.currentTarget.nextElementSibling.focus();
                  }
                }}/>

                <Input style={{margin:"0 8px", textAlign:"center", fontSize:"28px", color:"#666", outline:"none"}} placeholder="" key="confirmation-input2"  className="confirmation-input2" onChange={e=>{
                  if(e.currentTarget.value === ""){
                    e.currentTarget.previousElementSibling.focus();
                  }else{
                    e.currentTarget.nextElementSibling.focus();
                  }
                }}/>

                <Input style={{margin:"0 8px", textAlign:"center", fontSize:"28px", color:"#666", outline:"none"}} placeholder="" key="confirmation-input3"  className="confirmation-input3" onChange={e=>{
                 if(e.currentTarget.value === ""){
                    e.currentTarget.previousElementSibling.focus();
                  }else{
                    e.currentTarget.nextElementSibling.focus();
                  }
                }}/>

                <Input style={{margin:"0 8px", textAlign:"center", fontSize:"28px", color:"#666", outline:"none"}} placeholder="" key="confirmation-input4"  className="confirmation-input4" onChange={e=>{
                  if(e.currentTarget.value === ""){
                    e.currentTarget.previousElementSibling.focus();
                  }else{
                    e.currentTarget.nextElementSibling.focus();
                  }
                }}/>

<Input style={{margin:"0 8px", textAlign:"center", fontSize:"28px", color:"#666", outline:"none"}} placeholder="" key="confirmation-input5"  className="confirmation-input5" onChange={e=>{
                  if(e.currentTarget.value === ""){
                    e.currentTarget.previousElementSibling.focus();
                  }else{
                    e.currentTarget.nextElementSibling.focus();
                  }
                }}/>

<Input style={{margin:"0 8px", textAlign:"center", fontSize:"28px", color:"#666", outline:"none"}} placeholder="" key="confirmation-input6"  className="confirmation-input6" onChange={e=>{
                  if(e.currentTarget.value === ""){
                    e.currentTarget.previousElementSibling.focus();
                  }
                }}/>
                
                </Flex>
                </Box>

            <Button primary onClick={()=>{

              let pin = window.document.querySelector(".confirmation-input1").value;
                  pin += window.document.querySelector(".confirmation-input2").value;
                  pin += window.document.querySelector(".confirmation-input3").value;
                  pin += window.document.querySelector(".confirmation-input4").value;
                  pin += window.document.querySelector(".confirmation-input5").value;
                  pin += window.document.querySelector(".confirmation-input6").value;

              props.setLoading(true);


              window.confirmationResult.confirm(pin).then(result =>{

                props.setLoading(false);
                       //Define these as components and trigger with open state
              if(props.user.type === "worker"){
                
                props.addModal(<Modal  key={Math.random()} isOpen={true} backgroundProps={{style:{backgroundColor:"none", width:"100%", height:"100%"}}} afterClose={()=>{setFinishModalOpen(false);}}></Modal>);
              }else{
                props.addModal(<RegularModal isOpen={true}
                  onBackgroundClick={()=>{return false}}
          backgroundProps={{style:{backgroundColor:"none"}}} 
          >
                   <Heading style={{fontSize:"24px"}}>Your profile and job posting are all set!</Heading>
                  <Subheading style={{fontSize:"18px"}}>Contact pros that match your profile or search for new ones</Subheading>
<Button primary onClick={()=>{props.addModal(null); history.push("/jobs")}}>Continue</Button>
                </RegularModal>);
              }

                

                history.push(`${path}/congrats`);

            


              }).catch(error=>{
                console.log("Error");
                props.setLoading(false);
                 props.addModal(<Confirm isOpen={true} title="Verification failed.">
                   {error.error ? error.error.message : error.message}
                 </Confirm>)
              });
              
             
              
            }}>Submit</Button> 
            </Route>

            <Route path={`${path}/congrats`}>

            {/* This should be a component*/}
           
            <div style={{backgroundImage:"linear-gradient(130deg, #ffee00 12%, #f6bf00 77%)", borderRadius:"50%", position:"absolute", width:"200%", paddingTop:"100%", left:"50%", transform:"translateX(-50%)", top:"-65px"}}></div>

            <Box style={{zIndex:"1", height:"100%"}}>
            <Heading primary style={{letterSpacing:"0.76px", fontSize:"36px", padding:"0 16px 2rem 16px"}}>Congratulations & Welcome to Hiring Hall!</Heading>
            

            
            </Box>

             
            </Route>

            <Route path={`${path}/become-elite`}>
            
            <Box width={1}><Heading primary>Become an elite member by verifying your address and social security number</Heading></Box>

            <SecurityDisclaimer/>
            
            <EliteBadge  onClick={()=>{ props.addModal(<EliteInfoModal key={Math.random()} afterClose={()=>{setFinishModalOpen(false)}} isOpen={true} backgroundProps={{style:{backgroundColor:"none"}}}
            >
        <Logo src={hh_logo_yellow}/>
        <Box style={{color:"#fff", fontSize:"18px", textAlign:"center",fontWeight:"bold"}}>ELITE MEMBERS</Box>
        <StyledList>
          <li>Secured verification of qualified workers' personal information and certifications.</li>
          <li>Transparent view of a richer, more diverse pool of tradespeople.</li>
          <li>Always on. Instant, free access to pros 24/7</li>
          </StyledList>
          <UserPhoto user={{membership:"elite", photo:sampleImage, image:sampleImage}}/>
          <ContinueButton onClick={e=>{e.currentTarget.parentNode.parentNode.click();}}>Continue</ContinueButton>

      </EliteInfoModal>)}}/>
            <GrayLink onClick={()=>{setEliteModalOpen(true);

            props.addModal(<EliteInfoModal key={Math.random()} afterClose={()=>{setFinishModalOpen(false)}} isOpen={true} backgroundProps={{style:{backgroundColor:"none"}}}
            >
        <Logo src={hh_logo_yellow}/>
        <Box style={{color:"#fff", fontSize:"18px", textAlign:"center",fontWeight:"bold"}}>ELITE MEMBERS</Box>
        <StyledList>
          <li>Secured verification of qualified workers' personal information and certifications.</li>
          <li>Transparent view of a richer, more diverse pool of tradespeople.</li>
          <li>Always on. Instant, free access to pros 24/7</li>
          </StyledList>
          <UserPhoto user={{membership:"elite", photo:sampleImage, image:sampleImage}}/>
          <ContinueButton>Continue</ContinueButton>

      </EliteInfoModal>)
            }}>What's an Elite Member?</GrayLink>
            <Button primary><Link  onClick={()=>{setStepCount(11); setCurrentStep(5)}} to={`${path}/full-name`}>Next</Link></Button> 
            <Button knockedOut><Link to={`/jobs`}>Skip</Link></Button> 
            </Route>

            <Route exact path="/register/full-name">
            <Box width={1}>
                <Heading primary>What's your full name?</Heading>
            </Box>
            <Box width={1}>
                <Input placeholder="First Name" onChange={e=>{
                  let user = props.user;
                  user.first_name = e.currentTarget.value;
                  props.setUser(user);
                }}/>
                <Input placeholder="Middle Name"onChange={e=>{
                  let user = props.user;
                  user.middle_name = e.currentTarget.value;
                  props.setUser(user);
                }}/>
                <Input placeholder="Last Name"onChange={e=>{
                  let user = props.user;
                  user.last_name = e.currentTarget.value;
                  props.setUser(user);
                }}/>
                {/* <Input placeholder="Email"/> */}
            </Box>

            <Button primary><Link  onClick={()=>{
              updateUser(props.user);
            }} to={`${path}/home-address`}>Next</Link></Button> 
            </Route>

            <Route path={`${path}/home-address`}>
            <Box width={1}><Heading primary>What's your home address?</Heading></Box>
<Box width={1}>
<Input placeholder="Street"  id="street-address" key="street-address" onChange={e=>{
                  let user = props.user;
                  user.street = e.currentTarget.value;
                  props.setUser(user);
                }}/>
<Flex  alignItems="center" padding="0 12px" marginBottom="16px"  style={{fontFamily: "Roboto",
  fontSize: "16px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  color: "#0091ff"}} onClick={(clickEvent)=>{
   props.setLoading(true);
    navigator.geolocation.getCurrentPosition((e)=>{
       findAddress(e, (result)=>{
         let user = props.user;
         let options = result.results.filter(address=>{return address.address_components.length >5 ? true : false}).map((option,index)=>{
           return {name:option.formatted_address, id:index}
         });
         props.setLoading(false);
         props.createOptionsModal(options, checkedValue=>{
           console.log(result.results, checkedValue);
           let selectedOption = result.results[parseInt(checkedValue[0].id)];
              //Update user object
        user.street = selectedOption.address_components[0].long_name + " " + selectedOption.address_components[1].long_name;
        user.city = selectedOption.address_components[3].long_name;
        user.state = selectedOption.address_components[5].long_name;
        user.zipcode = selectedOption.address_components[7].long_name;
        props.setUser(user);

        //Update input fields
        document.querySelector("#street-address").value = selectedOption.address_components[0].long_name + " " + selectedOption.address_components[1].long_name;
        document.querySelector("#city-address").value = selectedOption.address_components[3].long_name;
        document.querySelector("#state-address").value = selectedOption.address_components[5].long_name;
        document.querySelector("#zip-address").value = selectedOption.address_components[7].long_name;

        let lat = e.coords.latitude;
        let long = e.coords.longitude;

        updateUser({...props.user, lat: lat, long:long});
       
         }, {singleValue:true, title:"Choose Location"});

       
        
       });
   
    }, (e)=>{});
  }}><img  alt="blue-locator" src={BlueLocator} style={{width:"1em", margin:"0 1em 0 0"}}/> Use current location</Flex>
<Flex><Input style={{flexGrow:1, minWidth:"70%"}} placeholder="City"  key="city-address"  id="city-address" value={props.user ? props.user.city : null}  onChange={e=>{
                  let user = props.user;
                  user.street = e.currentTarget.value;
                  props.setUser(user);
                }}/>
<Input style={{marginLeft:"12px"}} placeholder="State"   key="state-address"  id="state-address" value={props.user ? props.user.state : null}  onChange={e=>{
                  let user = props.user;
                  user.street = e.currentTarget.value;
                  props.setUser(user);
                }}/></Flex>
<Input placeholder="Zipcode"   key="zip-address"  id="zip-address" value={props.user ? props.user.zipcode : null}  onChange={e=>{
                  let user = props.user;
                  user.street = e.currentTarget.value;
                  props.setUser(user);
                }}/>
</Box>

            <Button primary><Link   onClick={()=>{updateUser(props.user);}} to={`${path}/ssn`}>Next</Link></Button> 
            </Route>


            



           

            

            

            <Route path={`${path}/ssn`}>
            <Box width={1}><Heading primary>What's your social security number?</Heading></Box>
<Flex width={1} alignItems="center" justifyContent="center" fontSize="32px" style={{whiteSpace:"nowrap"}}>
  
  <Box letterSpacing="0.25em">XXX-XX-</Box>
  <Flex justifyContent="space-between">
                <SingleDigitInput type="password" key="ssn-input1" onChange={e=>{
                  if(e.currentTarget.value === ""){}else{
                    e.currentTarget.nextElementSibling.focus();
                  }
                }}/>

                <SingleDigitInput type="password" key="ssn-input2" onChange={e=>{
                  if(e.currentTarget.value === ""){
                    e.currentTarget.previousElementSibling.focus();
                  }else{
                    e.currentTarget.nextElementSibling.focus();
                  }
                }}/>

                <SingleDigitInput type="password" key="ssn-input3" onChange={e=>{
                 if(e.currentTarget.value === ""){
                    e.currentTarget.previousElementSibling.focus();
                  }else{
                    e.currentTarget.nextElementSibling.focus();
                  }
                }}/>

                <SingleDigitInput type="password" key="ssn-input4" onChange={e=>{
                  if(e.currentTarget.value === ""){
                    e.currentTarget.previousElementSibling.focus();
                  }
                }}/>
                
                </Flex>
  
                
            
                
                
                </Flex>

<Button primary><Link   onClick={()=>{setCurrentStep(8)}} to={`${path}/profile-picture`}>Next</Link></Button>
            </Route>


            <Route path={`${path}/profile-picture`}>
            <Box width={1}><Heading primary>Let's add a photo!</Heading></Box>

                <Box>
                  <Camera style={{height:"auto"}}/>
                  <Button knockedOut onClick={
                    (event)=>{

                      let canvas = document.createElement('canvas');
                      let videoContainer = document.querySelector('.video-container');
                      let video = document.querySelector('video');
                  
                      
                      if(event.currentTarget.textContent === "Retake Photo"){
                        video.parentNode.insertBefore(video, document.querySelector("canvas#test"));
                        video.style.display = "block";
                        event.currentTarget.textContent = "Take Photo";
                        video.play();
                        setUserImageAvailable(false)
                        return
                      }
                      
                      
                      canvas.style = "position:absolute; top:0; left:0;"
                     // document.body.appendChild(canvas);
                      canvas.width = video.getBoundingClientRect().width;
                      canvas.height = video.getBoundingClientRect().height;
                      let ctx = canvas.getContext('2d');
                      // video.style.height = "auto";
                      // video.style.position = "absolute";
                      // video.style.top = "0";
                      // video.style.left = "50%";
                      // video.style.transform = "translateX(-50%)";
                      // video.style.width = "auto";
                      // video.style.height = "100%";
                      
                      let videoRatio = video.videoWidth/video.videoHeight;
                      let topOffset = 0;
                      let leftOffset = -1*((canvas.height*videoRatio)-canvas.width)/2;

                      //draw image to canvas. scale to target dimensions
                      ctx.drawImage(video, 0,0, video.videoWidth, video.videoHeight,leftOffset, topOffset, canvas.height*videoRatio, canvas.height);

                      if(videoRatio < 0){

                        leftOffset = 0;
                        topOffset = -1*((canvas.width/videoRatio)-canvas.height)/2;

                        //draw image to canvas. scale to target dimensions
                      ctx.drawImage(video, 0,0, video.videoWidth, video.videoHeight,leftOffset, topOffset, canvas.height*videoRatio, canvas.height);
                      }

                      video.parentNode.insertBefore(canvas, video);
                      video.pause();
                      video.style.display = "none";

                      event.currentTarget.textContent = "Retake Photo";
                      //convert to desired file format
                      let dataURI = canvas.toDataURL('image/png'); // can also use 'image/png';

                      let user = props.user;
                      user.image = dataURI;
                      props.setUser(user)
                      setUserImageAvailable(true)
                      setCurrentStep(8)
                    //  setCapture(dataURI);
                      return;
                  }}>Take Photo</Button>
                  <Button style={{position:"relative"}} knockedOut>Use a photo<input type="file" style={{position:"absolute", width:"100%", height:"100%", opacity:0, top:0, left:0}} onChange={event=>{
                        let file = event.currentTarget.file[0];
                        loadData(file, data=>{
                          let user = props.user;
                          user.image = data;
                          props.setUser(user)
                          setUserImageAvailable(true)
                          setCurrentStep(8)
                        });
                  }}/></Button>
                </Box>


{(userImageAvailable) ? <Button primary    onClick={()=>{updateUser(props.user);}}><Link to={`${path}/job-location`}>Next</Link></Button> : null}
            </Route>

            <Route path={`${path}/job-location`}>
            <Box width={1}><Heading primary>Where are you looking for jobs?</Heading></Box>
<Box width={1}>

<Input placeholder="Zipcode"  value={props.user ? props.user.zipcode : null}  onChange={e=>{
                  let user = props.user;
                  user.street = e.currentTarget.value;
                  props.setUser(user);
                }}/>
                <Flex alignItems="center" padding="0 12px" marginBottom="16px" style={{fontFamily: "Roboto",
  fontSize: "16px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  color: "#0091ff"}} onClick={(clickEvent)=>{
    let zipCodeInput = clickEvent.currentTarget.previousElementSibling;
    navigator.geolocation.getCurrentPosition((e)=>{
       findAddress(e, (result)=>{
         let user = props.user;
        user.zipcode = result.results[0].address_components[7].long_name
        zipCodeInput.value = user.zipcode;
        props.setUser(user);
       });
    }, (e)=>{});
  }}><img src={BlueLocator} alt="blue-locator" style={{width:"1em", margin:"0 1em 0 0"}}/> Use current location</Flex>
                </Box>

<Button onClick={()=>{updateUser(props.user);}} primary><Link to={`${path}/experience`}>Next</Link></Button>
            </Route>


            <Route path={`${path}/experience`}>
            <Box width={1}><Heading primary>Let’s add your professional experience.</Heading></Box>
<Box width={1}><Box>
<Box width={1}>
<SectionTitle style={{fontSize:"18px", fontWeight:"bold", textAlign:"left"}}><img src={DescriptionIcon}/> JOB DETAILS</SectionTitle>
<Input placeholder="Job title - i.e. Carpenter" onChange={e=>{
                 let user = props.user;
                 user.title = e.currentTarget.value;
                 props.setUser(user);
                }}/>
                <Input placeholder="Rate - $/hr"  onChange={e=>{
                   let user = props.user;
                   user.rate = e.currentTarget.value;
                   props.setUser(user);
                }}/>
                </Box>

                
                </Box>
                <SectionTitle style={{fontSize:"18px", fontWeight:"bold", textAlign:"left"}}><img src={SkillsIcon}/> SKILL LEVEL</SectionTitle>
                <Flex style={{borderBottom:"1px solid rgba(0,0,0,0.12)", paddinBottom:"12px", marginBottom:"16px", textTransform:"capitalize", textAlign:"left"}}>
              
                  <StyledSelect>
                    <option>Master</option>
                    <option>Intermediate</option>
                    <option>Notice</option>
                    </StyledSelect>
                <Box style={{background:"#fff", padding:"10px 0", pointerEvents:"none"}}><img src={rightChevron}/></Box>
</Flex>
            </Box>

<Button  onClick={()=>{updateUser(props.user);}} primary><Link to={`${path}/certification`}>Next</Link></Button>
            </Route>

            <Route path={`${path}/certification`}>
            <Box width={1}><Heading primary>Add licenses & certificates</Heading></Box>
            <Box>
             <SectionTitle style={{marginBottom:"16px"}}><img src={CertificatesIcon}/> LICENSES </SectionTitle>
             { props.user && props.user.licenses && props.user.licenses.map(license=>{
               return   <Flex style={{borderBottom:"1px solid rgba(0,0,0,0.12)", padding:"8px"}} className="option-item"><Label for={license.name}><Checkbox id={license.name} name={license.name} onChange={e=>{
                
                 }} defaultChecked="checked"/> {license.name}</Label></Flex>

             }) }
             <StyledTextAndOptions  width={1} onClick={()=>{
               //This array should filter out current licenses
  props.createOptionsModal([
    {name:"Asbestos Abatement", value:"Asbestos Abatement"},
    {name:"Boilmaker", value:"Boilmaker"},
    {name:"Electrician", value:"Electrician"},
    {name:"General Contractor", value:"General Contractor"},
    {name:"HVAC Technician", value:"HVAC Technician"},
    {name:"Mechanical Contractor", value:"Mechanical Contractor"},
    {name:"Plumbing", value:"Plumbing"},
    {name:"Rigger", value:"Rigger"},
    {name:"Welder", value:"Welder"},
    ].filter(license =>{
      
      if(props.user === null || typeof props.user.licenses === "undefined"){return true}

      if(props.user.licenses.filter(e=>{return e.name === license.name ? true : false}).length === 0){
        return true;
      }
      

   // return !props.user.licenses.includes(license)
    
    }),  (value)=>{
      let user = props.user;
      console.log(value)
      if(user.licenses){
        user.licenses = [...user.licenses, ...value];
      }else{
        user.licenses = value;
      }
                   
                   props.setUser(user);
   },{title:"Choose Certificate"})
}}>{"Select Licenses"}<img src={rightChevron}/></StyledTextAndOptions>
         </Box>

         <Box>
             <SectionTitle style={{marginBottom:"16px"}}><img src={SkillsIcon}/> CERTIFICATES</SectionTitle>
             { props.user && props.user.certificates && props.user.certificates.map(certificate=>{
               return (<Flex style={{borderBottom:"1px solid rgba(0,0,0,0.12)", padding:"8px"}} className="option-item"><Label for={certificate.name}><Checkbox id={certificate.name} name={certificate.name} onChange={value=>{console.log(value)}} defaultChecked="checked"/> {certificate.name}</Label></Flex>)
             })}

<StyledTextAndOptions  width={1} onClick={()=>{
  props.createOptionsModal([
    {name:"AIC", value:"AIC"},
    {name:"Airial Lift Training", value:"AIC"},
    {name:"CCM", value:"AIC"},
    {name:"CMCI", value:"AIC"},
    {name:"Crane Operators Certificate", value:"AIC"},
    {name:"LEED", value:"AIC"},
    {name:"NCEES", value:"AIC"},
    {name:"OSHA", value:"AIC"},
    {name:"PMP", value:"AIC"},
    ].filter(certificate =>{
      
      if(props.user === null || typeof props.user.certificates === "undefined"){return true}

      if(props.user.certificates.filter(e=>{return e.name === certificate.name ? true : false}).length === 0){
        return true;
      }
    
    }),  (value)=>{
      console.log(value)
                  let user = props.user;
                  if(user.certificates){
        user.certificates = [...user.certificates, ...value];
      }else{
        user.certificates = value;
      }
                   props.setUser(user);
   },{title:"Choose Certificate"})
}}>{"Select Certificates"}<img src={rightChevron}/></StyledTextAndOptions>

         </Box>



<Button onClick={()=>{
    let user = props.user;
                  user.membership = "elite";
                  props.setUser(user);

                  //Test registration APIs
                  updateUser(user, ()=>{
                    props.addModal(<Confirm title="Your profile is all set!" isOpen={true} confirmLabel="CONTINUE" cancelButton={<div></div>} ><p>Apply Instantly to jobs that match your profile or search for new ones.</p></Confirm>);
                    history.push("/jobs");
                  });

                 
}} primary>Submit</Button>
            </Route>

            
          </Switch>
     
      </Flex></>
      
      )
}
