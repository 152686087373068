import React, {useEffect, useState} from 'react';
import {Flex, Text, Box, LinkAnchor} from 'rebass';
import {
  useHistory
} from "react-router-dom";

import styled from 'styled-components';
import _ from 'lodash';


import ActionButton from '../components/ActionButton.js';
import AdCard from '../components/AdCard';
import Button from '../components/Button';
import Heading from '../components/Heading';
import Score from '../components/Score';
import Input from '../components/Input';
import Confirm from '../components/Confirm';
import FeedCard from '../components/FeedCard';
import Map from './Map';

import { Label, Textarea } from '@rebass/forms'
import {TweenMax} from 'gsap';
import calculateDistance from '../utilities/calculateDistance.js';

import ShareMenu from '../components/ShareMenu.js';

const ModalTitle = styled(Box)`font-size:24px; text-align:center;`;

const ScrollDragBox = styled.div`
    position:absolute;
    width:100%;
    height:50px;
    bottom:0;
    left:0;
`;


const StyledTextarea = styled(Textarea)`
    background: #d8d8d8;
    color:#4a4a4a;
    font-size:16px;
    border-radius:0;
    border:0;
    border-bottom: 1px solid #ffc600;
    width:100%;
    height:100%;
    font-family: 'Roboto';
    line-height:1.4
`;

const StyledLabel = styled(Label)`
    color: rgba(0, 0, 0, 0.38);
    padding: 8px;
    background: #d8d8d8;
`;


const Image = styled(Box)`width:100px; height:100px;`;
const Title = styled(Box)`font-size:24px; text-align:center;`;
const Location = styled(Box)`color:#4a4a4a; font-size:14px; font-weight:400; text-align:center;`;

const StyledActionButton = styled(ActionButton)`
position: absolute;
bottom: 16px;
right: 16px;
z-index: 1;
font-size: 50px;
color: #f6bf23;
line-height: 25px;
align-items: center;
padding-bottom: 7px;
box-sizing: border-box;
background:#000;
`




export default function Feed(props){
    props.setSection("Jobs");
    const[userLocation, setUserLocation] = useState(null);
    const[locationEnabled, setLocationEnabled] = useState(false);
    const[ShareToggled, setShareToggled] = useState(false);
    const[currentJob, setCurrentJob] = useState(null);

    const LocateUser = ()=>{
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position=>{
            setUserLocation({lat:position.coords.latitude, lng:position.coords.longitude})
          });
        }
      }

    useEffect(()=>{
        if(userLocation === null && locationEnabled){LocateUser();}
    });

    let history = useHistory();

    let filteredJobs = [...props.jobs];
    if(filteredJobs.length === 0 || typeof props.user === "undefined" || props.user === null){
        return null
    }
    if(props.user.type == "employer"){
        // filteredJobs = props.users;
    }
    
    /* Filter by title */
    if(props.filter){

        filteredJobs = filteredJobs.filter(job=>{
            let regex = new RegExp(`(^${props.filter.filter_by.titles[0]})\\w*.*\\w*`,"gi");
         //   console.log(regex, job.title, regex.test(job.title));
         let searchFilter = regex.test(job.title)
            if(props.filter.filter_by.titles.includes(job.title) || props.filter.filter_by.titles[0] == "all" || searchFilter){return true}
        });

        if(props.filter.filter_by.company){
        filteredJobs = filteredJobs.filter(job=>{
            let regex = new RegExp(`(^${props.filter.filter_by.company[0]})\\w*.*\\w*`,"gi");
         //   console.log(regex, job.title, regex.test(job.title));
         let searchFilter = regex.test(job.company)
            if(searchFilter){return true}
        });
    }

        

        filteredJobs = _.orderBy(filteredJobs, props.filter.sort_by, ['desc']);
    }

    filteredJobs = filteredJobs.filter(job=>{
        if(job.job_id){
            if(props.hidden.includes(job.job_id)){return false}
        }else if(job.user_id){
            if(props.hidden.includes(job.user_id)){return false}
        }
        return true
    });

    




  


    //This should reuse the FeedList Component
    const FeedList = filteredJobs.map((job,index) =>{
        try {
           
            console.log(props)
        if(job.type == "employer"){return null}

        let favoriteStatus = false;
        if((job.job_id && props.favorites.includes(job.job_id)) || (job.user_id && props.favorites.includes(job.user_id))){
            favoriteStatus = true;
        }

        let cardImage = null;
        let cardType = "job";

        //This would be filtered by an ID in the future
        if(job.type == "worker"){
            cardImage = job.image;
            cardType = "user";
        }else{
            // let filteredCompany = props.companies.filter(company=>{if(company.name === job.company){return true}});
            // if(filteredCompany.length > 0){
            //   cardImage = filteredCompany[0].logo;
            // }
        }
        let distance = null;
        if(userLocation){distance = (calculateDistance(userLocation.lat,userLocation.lng, parseFloat(job.lat), parseFloat(job.lng))*0.621371).toFixed(1);}
        console.log(props)
    
        if(props.user.type !== "employer" && props.user.membership !== "elite" && index % 4 == 0 && index !== 0){
            return(<><AdCard/><FeedCard {... job} distance={distance} saveJob={props.saveJob} unSaveJob={props.unSaveJob} removeHidden={props.removeHidden} addHidden={props.addHidden} cardImage={cardImage} contact={props.contact} favoriteStatus={favoriteStatus} type={cardType} setSnack={props.setSnack} user={props.user} addModal={props.addModal}/></>)
        }
       

        return(<FeedCard {...job} distance={distance} saveJob={props.saveJob}  unSaveJob={props.unSaveJob} removeHidden={props.removeHidden} addHidden={props.addHidden} cardImage={cardImage} contact={props.contact} favoriteStatus={favoriteStatus} type={cardType} setSnack={props.setSnack} user={props.user} addModal={props.addModal} share={()=>{setShareToggled(!ShareToggled)}}/>)

    } catch(e){
        console.error(e)
    }
    });

   

    for(let i=0;i<FeedList.length;i++){
        if((i+1) % 4 == 0 && i !== 0){
           // FeedList.splice(i,0,<AdCard/>)
        }
    }

    return(
       <>
    <Flex flexDirection="column" justifyContent="space-between" height="100%" style={{backgroundImage:' linear-gradient(to bottom, rgba(255, 217, 0, 0), #ffd900 4%, #ffc600 8%)', overflow:"auto", position:"relative"}} onLoad={(event)=>{
        event.currentTarget.scrollTop = 5
    }}  onScroll={(event)=>{
 
        let feedBox = event.currentTarget;
        let jobsMap = document.querySelector("#jobs-map");
        let feedBoxNode = document.querySelector("#feed-box");
      //  let CurrentJobCard = document.querySelector("#jobs-map div");

        jobsMap.addEventListener("click",(event)=>{
            event.currentTarget.querySelector("div").style.pointerEvents = "all";
        });

        if(feedBox.scrollTop < 5 && jobsMap.getBoundingClientRect().height >= 250){
         //   jobsMap.style.height = jobsMap.getBoundingClientRect().height + 5 + "px";
           // feedBox.scrollTop = feedBox.scrollTop +5;
           TweenMax.to(jobsMap,1,{minHeight:"calc("+jobsMap.parentNode.parentNode.getBoundingClientRect().height+"px)"});
           TweenMax.to(feedBoxNode,1,{top:"0"});
          // TweenMax.set(document.querySelectorAll(".feed-card"),{position:"relative", opacity:0, left:"100px"});
        //   TweenMax.set(jobsMap.querySelector("div"),{pointerEvents:"none"});
        }else if(feedBox.scrollTop > 5 && jobsMap.getBoundingClientRect().height > 250){
          //  jobsMap.style.height = jobsMap.getBoundingClientRect().height - 5 + "px";
           // feedBox.scrollTop = feedBox.scrollTop -5;
           TweenMax.to(jobsMap,1,{minHeight:"250px"});
           TweenMax.to(feedBoxNode,1,{top:"-100px"});
          // TweenMax.to(document.querySelectorAll(".feed-card:nth-child(1),.feed-card:nth-child(2),.feed-card:nth-child(3),.feed-card:nth-child(4)"),0.75,{position:"relative", marginLeft:"0", left:"0", opacity: 1, stagger: 0.75, delay:"+=1"});
        //   TweenMax.set(CurrentJobCard,{display:"none"});
        //   TweenMax.set(jobsMap.querySelector("div"),{pointerEvents:"none"});
        }
    }}>

        <Map id="jobs-map" filter={props.filter} jobs={filteredJobs} addApplication={props.addApplication} contact={props.contact } companies={props.companies} favorites={props.favorites}  saveJob={props.saveJob} unSaveJob={props.unSaveJob} removeHidden={props.removeHidden} addHidden={props.addHidden} contact={props.contact} setSnack={props.setSnack} user={props.user} addModal={props.addModal} setFilter={props.setFilter} setLocationEnabled={setLocationEnabled} locationEnabled={locationEnabled} userLocation={userLocation}/>

        <Box  id="feed-box" style={{padding:"16px", position:"relative", top:"-100px"}} data-results={filteredJobs.length}>
            {FeedList}
        </Box>
         
         <ScrollDragBox/>
         
      </Flex>
      <ShareMenu toggled={ShareToggled} facebook linkedin twitter instagram email share={()=>{}} setShareToggled={setShareToggled}  />
{props.user.type == "employer" && <StyledActionButton onClick={()=>{history.push('/jobs/new')}}>+</StyledActionButton>}</>
      )
}
