import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Box} from 'rebass';

const StyledCamera = styled.div`
max-width: 100%;
height:100%;
    padding-top: 100%;
    border-radius: 50%;
    overflow: hidden;
    position: relative;

    & video{
        position: relative;
        top: 0;
        left: 0;
        height:100%;
        max-height: 100%;
        object-fit: cover;
        width:100%;
    }
`;

const VideoContainer = styled(Box)`
    position:absolute;
    top:0;
    left:0;
    transfrom:translateX(-50%);
    height:100%;
    width:100%;
`;

export default function(props){


    const startCamera = (event)=>{
      
     
if(!navigator.mediaDevices){return}
        navigator.mediaDevices.getUserMedia({ audio: false, video: true })
.then(function(stream) {
  let video = document.querySelector('video');
  window.stream = stream;
  // Older browsers may not have srcObject
  if ("srcObject" in video) {
    video.srcObject = stream;
  } else {
    // Avoid using this in new browsers, as it is going away.
    video.src = window.URL.createObjectURL(stream);
  }
  video.onloadedmetadata = function(e) {
    video.play();
  };
})
.catch(function(err) {
  console.log(err.name + ": " + err.message);
});

    }

    useEffect(()=>{
        startCamera();
    });
    return <StyledCamera className="camera-container" {...props}>
      <VideoContainer className="video-container"><video id="test" /></VideoContainer>
    </StyledCamera>
}