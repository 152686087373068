import React from 'react';
import Modal from 'styled-react-modal';
import {useState, useEffect} from 'react';
import Button from './Button.js';
import styled from 'styled-components';
import Heading from './Heading.js';
import {Link} from 'react-router-dom';
import {Flex} from 'rebass';

const StyledModal = Modal.styled`
  width: 20rem;
  min-height: 20rem;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 7px 27px 0 #d3c07f;
  box-shadow: 0 19px 42px 0 #7d6000, 0 15px 12px 0 rgba(0, 0, 0, 0.22);
  padding:36px;
  box-sizing:border-box;
  text-align:left;
  max-height:100%;
  overflow:auto;
  & .option-item{padding:12px 0;}
`;

const LabelButton = styled.div`
    font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #f6bf00;
  padding: 0 0 0 16px;
  
`;

const StyledHeading = styled(Heading)`
    width:100%;
    font-size:24px;
    padding-bottom:0;
    &:after{
        width:100%;
        margin:12px 0;
    }
`;
 
export default function FancyModalButton(props) {

  const [isOpen, setIsOpen] = useState(props.isOpen);
  
  const [isClosed, setIsClosed] = useState(props.isClosed);


  useEffect(()=>{

      // if(props.isOpen && isOpen == false){
      //   setIsOpen(false);
      // }else if(props.isOpen && isOpen == true){
      //   setIsOpen(true);
      // }
  });

  //if(props.isOpen){setIsOpen(true)}
 
  function toggleModal(e) {
    setIsOpen(!isOpen);
  }

  function forceClose(e) {
    setIsClosed(!isClosed);
  }
 
    let openModal = props.isOpen;

    if(typeof props.isOpen == "undefined"){
        openModal = false;
    }

    openModal = props.isOpen;


    if(isClosed){
      openModal = false;
    }

    let confirmMessage = props.confirmLabel || "CONFIRM";
    let cancelMessage = props.cancelLabel || "CANCEL";

    const ConfirmButton =  props.confirmButton || <LabelButton onClick={()=>{console.log("Confirmed modal");if(props.onConfirm){props.onConfirm(true);} toggleModal()}}>{confirmMessage}</LabelButton>;
    const CancelButton =  props.cancelButton || <LabelButton onClick={toggleModal}>{cancelMessage}</LabelButton>;

    return (
        <StyledModal
          isOpen={isOpen}
          onBackgroundClick={toggleModal}
          backgroundProps={{style:{width:"100%", height:"100%"}}}
          onEscapeKeydown={toggleModal}>
         {props.title ? <StyledHeading>{props.title}</StyledHeading> : null}

        {props.children}
        <Flex style={{width:"100%", padding:"16px 0 0", alignContent:"center", justifyContent:"flex-end"}}>
        {CancelButton}
        {ConfirmButton}
       
        </Flex>
        </StyledModal>
    )
  
}