import React, {useRef, useState} from 'react';
import Score from '../Score';
import ProgressBar from '../ProgressBar';
import {Flex, Box} from 'rebass';

export default function Review(props){

    const toggleMore = (e)=>{
        
    }

    // score, reviewNumber, for(0-4): reviews.filter(score), progressBar, 

    let averageScore = 0;
    props.reviews.forEach(review=>{averageScore += review.score});
    averageScore = averageScore/props.reviews.length;
    

    return (<Box>


        <Flex alignItems={"center"} fontSize={"14px"} margin={" 0 0 24px 0;"}><Score score={averageScore.toFixed(2)} style={{fontSize:"32px", margin: "0 16px 0 0"}}/> {props.reviews.length} Reviews</Flex>


        <Flex alignItems={"center"} padding={"4px 0"}>
            <Box><Score score={5} style={{padding:"0", justifyContent:"right"}} removeEmpty={true}/></Box>
            <Flex flexGrow={1} padding={"0 0 0 24px"} justifyContent={"space-between"} fontSize={"12px"} lineHeight={"1"}><ProgressBar progress={(props.reviews.filter(review=> review.score === 5).length/props.reviews.length).toFixed(2)} style={{width:"calc(100% - 32px)"}}/> {props.reviews.filter(review=> review.score === 5).length}</Flex>
        </Flex>

        <Flex alignItems={"center"} padding={"4px 0"}>
            <Box><Score score={4} style={{padding:"0", justifyContent:"right"}} removeEmpty={true}/></Box>
            <Flex flexGrow={1} padding={"0 0 0 24px"} justifyContent={"space-between"} fontSize={"12px"} lineHeight={"1"}><ProgressBar style={{width:"calc(100% - 32px)"}} progress={(props.reviews.filter(review=> review.score === 4).length/props.reviews.length).toFixed(2)}/> {props.reviews.filter(review=> review.score === 4).length}</Flex>
        </Flex>

        <Flex alignItems={"center"} padding={"4px 0"}>
            <Box><Score score={3} style={{padding:"0", justifyContent:"right"}} removeEmpty={true}/></Box>
            <Flex flexGrow={1} padding={"0 0 0 24px"} justifyContent={"space-between"} fontSize={"12px"} lineHeight={"1"}><ProgressBar style={{width:"calc(100% - 32px)"}} progress={(props.reviews.filter(review=> review.score === 3).length/props.reviews.length).toFixed(2)}/> {props.reviews.filter(review=> review.score === 3).length}</Flex>
        </Flex>

        <Flex alignItems={"center"} padding={"4px 0"}>
            <Box><Score score={2} style={{padding:"0", justifyContent:"right"}} removeEmpty={true}/></Box>
            <Flex flexGrow={1} padding={"0 0 0 24px"} justifyContent={"space-between"} fontSize={"12px"} lineHeight={"1"}><ProgressBar style={{width:"calc(100% - 32px)"}} progress={(props.reviews.filter(review=> review.score === 2).length/props.reviews.length).toFixed(2)}/> {props.reviews.filter(review=> review.score === 2).length}</Flex>
        </Flex>

        <Flex alignItems={"center"} padding={"4px 0"}>
            <Box><Score score={1} style={{padding:"0", justifyContent:"right"}} removeEmpty={true}/></Box>
            <Flex flexGrow={1} padding={"0 0 0 24px"} justifyContent={"space-between"} fontSize={"12px"} lineHeight={"1"}><ProgressBar style={{width:"calc(100% - 32px)"}} progress={(props.reviews.filter(review=> review.score === 1).length/props.reviews.length).toFixed(2)}/> {props.reviews.filter(review=> review.score === 1).length}</Flex>
        </Flex>
    </Box>)
}

