import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

const Container = styled(Box)`
width: 100%;
background: #d8d8d8;
height: 12px;
position:relative;
`;
const Bar = styled(Box)`
position: absolute;
width: 100%;
height: 100%;
top: 0;
left: 0;
background: #ffc600;
`;

export default function ProgressBar(props){


  return (<Box {... props} width={"100%"}>
               <Container><Bar style={{width:`calc(100% * ${props.progress})`}}/></Container>
             </Box>);
}

