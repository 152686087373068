import React, {useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import SampleLogo from '../sample_logo.png';
import {CalendarIcon, LocationIcon} from './Icons'
import HeartIcon from '../icons/heart_icon.svg';
import YellowHeart from '../icons/yellow_heart.svg';
import ShareIcon from '../icons/share_icon.svg';
import Score from './Score.js';
import UserPhoto from './UserPhoto';
import Confirm from './Confirm';
import Button from './Button'
import Card from './Card'

const CardAction = styled(Box)`
padding: 8px;
opacity: 1;

border-radius: 50%;
display: flex;
align-items: center;

border: 0px solid #ccc;
filter:saturate(1);

&.toggled{
  opacity: 1;
  filter:saturate(0);
}
`;

const JobImage = styled.img`
  width:100%;
  max-width:100px
`;

export default function FeedCard(props){

  let history = useHistory();

  const [actionText, setText] = useState(props.user_id && props.contacted ? "CONTACTED" : props.user_id ? 
  "CONTACT" : props.job_id && props.contacted ? "APPLIED" : "APPLY")

  const ref = useRef();

    if(typeof(props.title) === "undefined"){return null}

    let savedOpacity = 0.5;
    if(props.favoriteStatus){
      savedOpacity = 1;
    }

    let contactText = "APPLY";
    if(props.contacted){
      contactText = "APPLIED"
    }

    if(props.user_id){
      contactText = "CONTACT";
      if(props.contacted){
        contactText = "CONTACTED"
      }
    }

    let cardImage = props.cardImage;

    let dateObject = typeof props.date === "object" ? new Date(props.date.seconds) : null;

    let dateString = dateObject ? (dateObject.getDate()+"/"+dateObject.getMonth()+1+"/"+dateObject.getFullYear()) : props.date;


    return (<Card ref={ref} className="feed-card" id={props.user_id || props.job_id} {... props}>

  <Flex style={{padding:"16px"}}  onClick={()=>{
    if(props.job_id){
      history.push("/job/"+props.job_id)
    }else if(props.user_id){
      history.push("/worker/"+props.user_id)
    }
    }}>
             <Box style={{textAlign:"left", flex:"0 60%"}}>
                 <Box style={{fontSize:"24px"}}>
                <Box>{props.title}</Box>
                 </Box>
                <Box style={{fontSize:"20px", fontWeight:"bold"}}>{props.job_id ? "$"+props.rate+"/h" : (props.first_name +" "+ props.last_name)}</Box>
                 <Box style={{fontSize:"14px", color:"#4a4a4a"}}>{props.company}</Box>
                 <Box style={{fontSize:"14px", color:"#4a4a4a"}}>{props.location}</Box>
                 <Score style={{width:"fit-content", marginTop:"8px"}} score={props.rating}/>
             </Box>
             <Flex style={{textAlign:"right", flex:"0 40%"}} flexDirection="column" justifyContent="space-between">
                 <Box>{props.type == "user" ? <UserPhoto user={props}style={{width:"65px",height:"65px", marginLeft:"auto", marginBottom:"16px"}}/> : <JobImage src={props.cardImage || SampleLogo} style={props.type == "user" ? {width:"65px",height:"65px", borderRadius:"50%", objectFit:"cover"} : {}}/>}</Box>
                 <Box>
                     <Flex style={{color:"#4a4a4a", fontSize:"12px", alignItems:"center", justifyContent:"flex-end", marginBottom:"4px"}}><img src={LocationIcon} style={{width:"12px", marginRight:"8px"}}/> {props.distance ? (props.distance +"mi") : "Not available"}</Flex>
                     {props.type === "user" ? null : <Flex style={{color:"#4a4a4a", fontSize:"12px", alignItems:"center", justifyContent:"flex-end"}}><img src={CalendarIcon} style={{width:"12px", marginRight:"8px"}}/> {props.date ? dateString : "Not available"}</Flex>}
                 </Box>
             </Flex>
         </Flex>
         <Flex style={{borderTop:"1px solid rgba(0, 0, 0, 0.12)", padding:"16px", boxSizing:"border-box"}} justifyContent="space-between" alignItems="center">
            <Flex>
              <Box style={{fontSize:"14px", fontWeight:"600", color:"#f6bf00", letterSpacing:"0.5px", padding:"0 16px 0 0"}} onClick={e=>{

                  console.log("Changing label text")
                  if(props.job_id && !props.contacted){ console.log("Changing to applied"); setText("APPLIED");}
                  if(props.user_id && !props.contacted){ console.log("Changing to contacted"); setText("CONTACTED")}

                props.contact({title:props.title, status: 0, rate:props.rate, location:props.location, company:props.company, rating:props.rating, date:props.date, job_id:props.job_id, user_id:props.user_id, image:props.image, first_name:props.first_name,last_name:props.last_name, membership:props.membership});
                
                // Change contacted/applied flag on job
                // This logic could also exist higher up by checking for the job/worked ID being present in the user's contacted lists
              

              }}>{actionText}</Box>
              <Box style={{fontSize:"14px", fontWeight:"600", color:"#f6bf00", letterSpacing:"0.5px", padding:"0 16px"}} onClick={e=>{
             
                let card = e.currentTarget.parentNode.parentNode.parentNode;
                card.classList.toggle("hidden");
                let id = props.user_id ? props.user_id : props.job_id;
                setTimeout(()=>{
                  card.style.display = "none"; 
                props.addHidden(id); 
                props.setSnack({id: Math.random(),title: "Job hidden", actionText:"Unhide", action:()=>{
                  ref.current.style.display = "block";
                  setTimeout(()=>{ ref.current.classList.remove("hidden");},100);
                 
                 // props.removeHidden(id);
                  props.setSnack(null)
                }})}, 500);
              }}>HIDE</Box>
            </Flex>
            <Flex>
              <CardAction className={props.favoriteStatus ? "toggled" : ''} onClick={e=>{
                console.log(props, props.job_id)
                if(! e.currentTarget.classList.contains("toggled")){
                  e.currentTarget.classList.add("toggled");
                  props.saveJob(props.job_id);
               
                props.setSnack({id: Math.random(),title: "Job Saved", actionText:"Unsave", action:()=>{
                  props.unSaveJob(props.job_id);
                  props.setSnack(null)
                }});
                }else{
                  e.currentTarget.classList.remove("toggled");
                  props.unSaveJob(props.job_id);
                  props.setSnack({id: Math.random(),title: "Job Unsaved", actionText:"Save", action:()=>{
                  props.saveJob(props.job_id);
                  props.setSnack(null)
                }});
                }
              }}><img src={YellowHeart}/></CardAction>
              <CardAction><img src={ShareIcon}/></CardAction>
            </Flex>
         </Flex>
    </Card>);
}

