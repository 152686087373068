function loadData(file, callback) {
     
    var reader = new FileReader();
    
    if(file){reader.readAsDataURL(file);}
    else{return false}
      
    reader.addEventListener("load", function() {
      var dataSource = reader.result;
      var extension = file.name.split('.').pop().toLowerCase()

      var loadedData = {
        source : dataSource,
        type : extension
      }
      callback(loadedData);
      return loadedData;
    }, false);  
  
}
export default loadData;