import React, {useState} from 'react';

import {Label, Input} from '@rebass/forms';

import loadData from '../loadData.js';
import UserHeader from '../components/UserHeader.js';
import UserPhoto from '../components/UserPhoto.js';

import { Switch as SwitchComponent }  from '@rebass/forms';
import { Button } from 'rebass';
import firebase from 'firebase';

const updateUserOnDatabase = (user) =>{
    let db = firebase.firestore();
    let docRef = db.collection("users").doc(user.uid);
    //  docRef.get().then(doc=>{console.log(message, doc.data())
    console.log(user, docRef);
     docRef.update({...user}).then((docRef) => {
      //console.log("Document written with ID: ", docRef.id);
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
}

export default function EditProfile(props){
    props.setSection("Edit Profile");

    const changeValue = event =>{
        if(event.currentTarget.id == "name-field"){
            props.user.name = event.currentTarget.value;
            props.setUser(props.user);
        }

        if(event.currentTarget.id == "first-name-field"){
            props.user.first_name = event.currentTarget.value;
            props.setUser(props.user);
        }
        if(event.currentTarget.id == "last-name-field"){
            props.user.last_name = event.currentTarget.value;
            props.setUser(props.user);
        }

        if(event.currentTarget.id == "location-field"){
            props.user.location = event.currentTarget.value;
            props.setUser(props.user);
        }

       

        if(event.currentTarget.id == "trade-field"){
            props.user.title = event.currentTarget.value;
            props.setUser(props.user);
        }

        if(event.currentTarget.id == "image-field"){
            loadData(event.currentTarget.files[0], (data)=>{
                props.user.image = data.source;
                props.setUser(props.user);
            });
        }

        if(event.currentTarget.id == "level-field"){
            loadData(event.currentTarget.files[0], (data)=>{
                props.user.image = data.source;
                props.setUser(props.user);
            });
        }

        props.updateUser({...props.user});
    };

   return <div><Button onClick={()=>{
    updateUserOnDatabase(props.user)
   }}>SAVE</Button>
       <UserHeader user={props.user}  style={{backgroundImage:"linear-gradient(127deg, #ffee00 12%, #f6bf00 76%)", borderBottom:"solid 1px #666"}}/>
    {/* <UserPhoto image={props.user.image} membership={props.user.membership || "elite"}/> */}
      <Label>Photo</Label>
<Input id="image-field" type="file"  onChange={changeValue}/>

       <Label>First Name</Label>
       <Input id="last-name-field" defaultValue={props.user.first_name} onChange={changeValue}/>

       <Label>Last Name</Label>
       <Input id="first-name-field" defaultValue={props.user.last_name} onChange={changeValue}/>

       <Label>Username</Label>
       <Input id="username-field" defaultValue={props.user.username} onChange={changeValue}/>

       {/* <Label>Password</Label>
       <Input id="password-field" defaultValue={props.user.password} onChange={changeValue}/> */}

       <Label>Email</Label>
       <Input id="email-field" defaultValue={props.user.email} onChange={changeValue}/>

       {/* <Label>membership (admin)</Label>
       <Input id="membership-field" defaultValue={props.user.membership} onChange={changeValue}/> */}

       {/* <Label>Phone</Label>
       <Input id="phone-field" defaultValue={props.user.phone} onChange={changeValue}/> */}

       <Label>Location</Label>
       <Input id="location-field" defaultValue={props.user.location} onChange={changeValue}/>

       <Label>Trade</Label>
       <Input id="trade-field" defaultValue={props.user.trade} onChange={changeValue}/>

       <Label>Level</Label>
       <Input id="level-field" defaultValue={props.user.level} onChange={changeValue}/>


       <Label>Union</Label>
       <SwitchComponent onChange={changeValue} alignSelf={"flex-start"}/>

       

   </div>;
}
