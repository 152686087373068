import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import Subheading from './Subheading.js';
import SampleLogo from '../sample_logo.png';
import LocationIcon from '../icons/location_icon.svg';
import CalendarIcon from '../icons/calendar_icon.svg';
import HeartIcon from '../icons/heart_icon.svg';
import ShareIcon from '../icons/share_icon.svg';
import Logo from '../hh_logo_yellow.svg';
import Background from '../iron_workers_color.png';

const StyledFeedCard = styled(Flex)`
    border-radius: 11px;
  box-shadow: 0 5px 14px 0 #9a5500, 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-style: none;
  border-width: 0.5px;
  border-image-source: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.02) 95%, rgba(0, 0, 0, 0.04));
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.02) 95%, rgba(0, 0, 0, 0.04));
  background-origin: border-box;
  background-clip: content-box, border-box;

  & img{max-width:120px; margin:0 auto;}
`;

const SignupRibbon = styled(Box)`
        background: #FFD900;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    top: 24px;
    right: -31px;
    transform: rotate(45deg);
    padding: 4px 24px;
`;


export default function AdCard(props){



    return (<StyledFeedCard className="ad-card" flexDirection="column" style={{position:"relative", overflow:"hidden", padding:"32px 16px", marginBottom:"16px", background:'#4d4d4d', zIndex:"0"}}>
        <Box style={{background:`#000 url(${Background}) no-repeat center / cover`, position:"absolute", width:"100%", height:"100%", zIndex:"-1", mixBlendMode:"multiply", opacity:"0.4", top:0, left:0}}></Box>
                <SignupRibbon>Sign up now!</SignupRibbon>
                <img src={Logo}/>
                <Subheading style={{color:"#fff", textAlign:"center", margin:"15px auto"}}>BECOME AN ELITE MEMBER</Subheading>
                <ul style={{color:"#F6BF00", margin:"0 auto", width:"auto", textAlign:"left", lineHeight:"1.5"}}>
                    <li>Unlimited job applications</li>
                    <li>Prefered search results listing</li>
                    <li>Profile assistance</li>
                </ul>
    </StyledFeedCard>);
}

