import firebase from 'firebase';

const addComment = (post, comment, user, callback) =>{
    console.log(post, comment, user)
      let db = firebase.firestore();
      let docRef = db.collection('posts').doc(post.post_id.toString());

      let newComment = {
        message:comment, 
        from:{user_id: user.uid, username: user.username},
        about: post.post_id,
        date: Date.now(),
        approved: true
    }
      

      // Update post comments array with latest comments
      let newComments = post.comments.slice(0,2);
      newComments.unshift(
        newComment
    );
      // Add new comment document to comments collection
      
      docRef.update({comments: newComments, commentCount: firebase.firestore.FieldValue.increment(1)},{merge: true}).then(result=>{

       
               //   callback({type:"comment", count: 1, post_id: post.post_id, comment: comment},result);
               
      }).catch(error=>{});

      let commentsRef = db.collection('comments');
      commentsRef.add(newComment).then(result =>{
         callback(newComment, result);
      }).catch(error=>{});
}

export default addComment;