import React, {useState, useEffect} from 'react';
import {Flex, Box} from 'rebass';
import styled from 'styled-components';

const StyledSnackBar = styled(Flex)`
    position: absolute;
    bottom: 74px;
    left: 16px;
    width: calc(100% - 32px);
    height: 65px;
    z-index: 1;
    color: #fff;
    border-radius:4px;
    background:#666;
    align-items:center;
    padding:16px;
    transition: all .25s ease-in-out;
    opacity:1;
    pointer-events:all;
    &.hidden{
        opacity: 0;
        pointer-events:none;
    }
`;

const SnackTitle = styled(Box)`
    flex-grow:1;
    text-align:left;

`;
const SnackAction = styled(Box)`
    color:${props => props.theme.lightYellow};
`;

export default function Snackbar(props) {
    const [snack, updateSnack] = useState(props.snack);


    useEffect(()=>{
     
    });

    if(props.snack == null){
       // return null
    }else{
        if(window.snackTimer){clearTimeout(window.snackTimer)}
        window.snackTimer = setTimeout(()=>{
           props.setSnack(null);
       },3000);
    }



  return (<StyledSnackBar {... props} className={props.snack ? " " : "hidden"}>
      <SnackTitle {...props}>{props.snack ? props.snack.title : ''}</SnackTitle>
      <SnackAction {...props} onClick={props.snack ? props.snack.action : ''}>{props.snack ? props.snack.actionText : ()=>{}}</SnackAction>
  </StyledSnackBar>);
}



