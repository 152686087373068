import React from "react";
import {Flex, Box} from 'rebass';
import styled from 'styled-components';

const StyledOptionItem = styled(Box)`
    display: inline-block;
    margin: 2px;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 400;
    min-width: 35px;
    text-align: center;
    cursor: pointer;
    background-position: center 3px;
    background-size: 35px;
    background-repeat: no-repeat;
    border-radius: 6px;
    border: 1px solid #ccc;
    box-sizing: border-box;

    & .icon{
      width: 65px;
    height: 65px;
    border-radius: 50%;
    border: 3px solid #9b9b9b;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;

    & img{max-width:90%; max-height:90%;}
    }
`;

export default class OptionGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
      options: this.props.options,
      columns: this.props.columns,
      action: this.props.action,
    };

    this.selectOption = this.selectOption.bind(this);
    this.triggerAction = this.triggerAction.bind(this);
  }

  componentDidUpdate(prevProps) {
      
    
    if(this.props.value == "" || typeof this.props.value == "undefined" && this.state.value !== null){
      this.setState({ value: null });
      return;
    }

    if (this.state.value !== this.props.value) {
      for (var i = 0; i < this.props.options.length; i++) {
        if (this.props.options[i].value == this.props.value) {
          this.setState({ value: this.props.options[i].value });
        }
      }
    }
  }

  componentDidMount() {
    for (var i = 0; i < this.props.options.length; i++) {
      if (this.props.options[i].value == this.state.value) {
        this.setState({ value: this.props.options[i].value });
      }
    }
  }

  selectOption(selectedValue) {
    // this.props.value = selectedValue;
    this.setState({ value: selectedValue });
  }

  triggerAction(value) {
    this.state.action(value);
  }

  render() {
    let selectOption = this.selectOption;
    let action = this.triggerAction;
    let self = this;

    const optionsArray = this.props.options.map(function (option, index) {
      let classes = "option-item";

      if (self.state.columns > 0) {
        classes += " column-" + self.state.columns;
      }

      if (self.state.value === option.value) {
        classes += " toggled";
      }

      if (typeof option.icon == "undefined") {
        option.icon = "";
      }

      return React.createElement(StyledOptionItem, {
        key: index,
        "data-value": option.value,
        className: classes,
        onClick: function (event) {
          selectOption(option.value);
          action(option.value);
        }
      }, React.createElement("div",{className:"icon"},option.icon),React.createElement("div",{className:"label"},option.name));
    });

    if (typeof this.props.label !== "undefined") {
      return React.createElement(
        "div",
        { className: "option-group"+this.props.className, style: this.props.style },
        React.createElement(
          "span",
          { className: "property-label"+this.props.className, style: { display: "block" } },
          this.props.label
        ),
        optionsArray
      );
    } else {
      return React.createElement(
        "div",
        { className: "option-group "+this.props.className, style: this.props.style },
        optionsArray
      );
    }
  }
}
