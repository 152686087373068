import React, { useCallback } from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import SampleLogo from '../sample_logo.png';
import LocationIcon from '../icons/location_icon.svg';
import CalendarIcon from '../icons/calendar_icon.svg';
import HeartIcon from '../icons/heart_icon.svg';
import ShareIcon from '../icons/share_icon.svg';
import Score from './Score.js';
import likeIcon from '../icons/like_icon.svg';
import shareIcon from '../icons/social_share_icon.svg';
import commentIcon from '../icons/comment_icon.svg';
import connectIcon from '../icons/friend_icon.svg';
import UserPhoto from '../components/UserPhoto.js';


import firebase from 'firebase';
import ActionButton from './ActionButton.js';
import addComment from '../network/comments/addComment.js';
import getComments from '../network/comments/getComments.js';

import millisecondsToTimeElapsedSince from '../utilities/millisecondsToTimeEllapsedSince.js';

const StyledFeedCard = styled(Flex)`
    border-radius: 0px;
  box-shadow: 0 5px 14px 0 #9a5500, 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0.5px;
  border-image-source: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.02) 95%, rgba(0, 0, 0, 0.04));
  border-image-slice: 1;
  
  background-origin: border-box;
  background-clip: content-box, border-box;

  & a{color:inherit; text-decoration:none;}

  margin: 24px 0 19px;
  padding: 0;
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
`;

const UserName = styled(Box)`
   font-size:16px;
   color:#000;
   font-weight:500;
`;

const Caption = styled(Box)`
   font-size:16px;
   padding:16px;
   text-align:left;
`;

const Location = styled(Box)`
   font-size:12px;
   color:#666;
   
`;

const Options = styled(Box)`
   display:flex;
   align-items:center;
   font-size:24px;
   color:#000;
`;

const FeedImage = styled(Box)`
min-height: 300px;
backgroud: linear-gradient(to bottom, rgb(255 218 3),  rgb(246,191,0) )
   & img{width:100%;}
`;

const StyledUserPhoto = styled(UserPhoto)`
   & .badge{width:24px; left:-8px}
`;

const PostAction = styled(Box)`
   & img{
       filter: invert(0);
       transition: all .3s ease-in-out;
   }
   &.toggled img{
       filter: invert(1) !important;
   }
   transition: all .3s ease-in-out;
`;


const PostActionIcon = styled(Box)`
width: 36px;
height: 36px;

  background: url('${props => props.src}') center/contain no-repeat;
border-radius: 50px;
border: 1px solid #ccc;
background-size: 21px;

.toggled & {
   border-color-top: #fdfdfd;
   border-color: #666;
   background-color: rgb(255 218 3);
}
   `;

const CommentList = styled(Flex)`
   max-height: 0px;
   overflow: hidden;
   flex-direction: column;
   text-align: left;
   &.toggled{
      max-height: 1000px;
      padding: 0 16px 16px;
   }

   & .comments-text {
      color: #666;
      font-size: 14px;
   }
   & .comments-container {
      padding: 8px 0;
   }
   & .comments-item {
      position: relative;
      border-bottom: 1px solid rgba(0,0,0,0.05);
    padding: 8px 0;
   }
   & .comments-item-message {
      font-size: 15px;
      color: #333;
      font-style: italic;
   }
   & .comments-item-username {
      font-size: 11px;
      margin-top: 8px;
   }

   & .comments-item-date {position: absolute; top: 2px; right: 2px; font-size: 11px;}
`;

const CommentField = styled(Flex)`
flex-direction: row;
margin-top: 8px;
`;

const CommentInput = styled.input`
flex-grow: 1;
    padding: 12px;
    border-radius: 60px 0 0 60px;
    border: none;
    background: rgba(0,0,0,0.05);

`;
const CommentButton = styled.button`
border: none;
    background: #ffdb01;
    border-radius: 0 60px 60px 0;
`;


export default function CameraFeedItem({user, post, reactions, comments,commentCount, connections, setShareMenuOpen, image, post_id, caption}){

   const [newComment, setNewComment] = React.useState('');
   const [allComments, setAllComments] = React.useState(comments || []);

   

   const addReaction = (post_id, callback, type) =>{
      let db = firebase.firestore();
      let docRef = db.collection('posts').doc(post_id.toString());
      
      docRef.update({reactions: firebase.firestore.FieldValue.arrayUnion({
         type:"like", 
         count: 1, 
         post_id: post_id, 
         user_id: firebase.auth().currentUser.uid,
        })},{merge: true}).then(docRef=>{

         let userRef = db.collection('users').doc(firebase.auth().currentUser.uid);
       
         userRef.update({reactions: firebase.firestore.FieldValue.arrayUnion({type:"like", count: 1, post_id: post_id})},{merge: true}).then((result)=>{

               if(callback){
                  callback({type:"like", count: 1, post_id: post_id},result);
               }

         }).catch(error=>{});
      }).catch(error=>{});
}

const removeReaction = (post_id, callback, type) =>{
   let db = firebase.firestore();
   let docRef = db.collection('posts').doc(post_id.toString());
   
   docRef.update({reactions: firebase.firestore.FieldValue.arraRemove({
      type:"like", 
      count: 1, 
      post_id: post_id, 
      user_id: firebase.auth().currentUser.uid,
     })},{merge: true}).then(docRef=>{

      let userRef = db.collection('users').doc(firebase.auth().currentUser.uid);
    
      userRef.update({reactions: firebase.firestore.FieldValue.arrayUnion({type:"like", count: 1, post_id: post_id})},{merge: true}).then((result)=>{

            if(callback){
               callback({type:"like", count: -1, post_id: post_id},result);
            }

      }).catch(error=>{});
   }).catch(error=>{});
}

const connectWithUser = (user_id, callback) =>{
   let db = firebase.firestore();
   let docRef = db.collection('users').doc(firebase.auth().currentUser.uid);
   
   docRef.update({connections: firebase.firestore.FieldValue.arraUnion({
      type:"worker", 
      approved: false,
      user_id: user_id,
     })},{merge: true}).then(docRef=>{

      let userRef = db.collection('users').doc(user_id);
    
      userRef.update({reactions: firebase.firestore.FieldValue.arrayUnion({type:"worker", user_id: user_id})},{merge: true}).then((result)=>{

            if(callback){
               callback({type:"like", count: -1, user_id: user_id},result);
            }

      }).catch(error=>{});
   }).catch(error=>{});
}

const removeUserConnection = (user_id, callback) =>{
   let db = firebase.firestore();
   let docRef = db.collection('users').doc(firebase.auth().currentUser.uid);
   
   docRef.update({connections: firebase.firestore.FieldValue.arraRemove({
      type:"worker", 
      approved: false,
      user_id: user_id,
     })},{merge: true}).then(docRef=>{

      let userRef = db.collection('users').doc(user_id);
    
      userRef.update({reactions: firebase.firestore.FieldValue.arrayRemove({type:"worker", user_id: user_id})},{merge: true}).then((result)=>{

            if(callback){
               callback({type:"connection", count: -1, user_id: user_id},result);
            }

      }).catch(error=>{});
   }).catch(error=>{});
}

   let likeCount = reactions ? reactions.filter(r=> r.type === "like").length : 0;

   let shareCount = reactions ? reactions.filter(r=> r.type === "share").length : 0;

   //let commentCount = comments ? comments.filter(r=> r.approved === true).length : 0;
   //let commentCount = commentC;

   let connectionsCount = connections ? reactions.filter(r=> r.type === "like").length : 0;

   // let commentList = comments || [
   //    {message: "Hello World 1", from: {username:"johndoe", user_id: "johndoe"}},
   //    {message: "Hello World 2", from: {username:"janedoe", user_id: "johndoe"}},
   //    {message: "Hello World 3", from: {username:"juanperez", user_id: "johndoe"}},
   // ];

  
    return (<StyledFeedCard key={post_id} className="camera-feed-card" flexDirection="column" style={{marginBottom:"16px"}} image={image} caption={caption}>
    
  <Flex flexDirection="column">
             <Flex padding="16px" justifyContent="space-between">
                {user ? <StyledUserPhoto user={user} style={{width:"48px",height:"48px"}} /> : null}
                <Flex  padding="0 16px" alignItems="center" textAlign="left" flexGrow="1">
                   <Box>
                   {user ? (<UserName>{user.first_name+" "+user.last_name}</UserName>) : null}
                   {user ? (<Location>{user.location}</Location>) : null}
                  </Box>
                </Flex>
                <Options>...</Options>
             </Flex>

             <Caption>
                {caption}
             </Caption>

             <FeedImage>
                <img src={image}/>
             </FeedImage>
             <Flex justifyContent="space-between" padding="16px">
                <PostAction key="like-button" className={reactions && reactions.find(r=>r.user_id === firebase.auth().currentUser.uid) ? "toggled" : ''} onClick={(e)=>{
                   let likeButton = e.currentTarget;
                   likeButton.classList.toggle("toggled");
                   
                   addReaction(post_id, (reaction, docRef)=>{
                      console.log(docRef, likeButton,  likeCount, reaction);
                      likeButton.querySelector("div").textContent = likeCount + reaction.count;
                   }, "like");
                }}>
                  <PostActionIcon  src={likeIcon}/>
              


                  <Box key="like-count">{likeCount}</Box>
                </PostAction>

                <PostAction key="share-button" ><PostActionIcon  src={shareIcon} onClick={(e)=>{
                  setShareMenuOpen(true);
                }}/>
                  <Box key="like-count">{shareCount}</Box>
                </PostAction>

                <PostAction key="comment-button" onClick={(e)=>{
                   let likeButton = e.currentTarget;
                   likeButton.classList.toggle("toggled");

                   let commentList = likeButton.parentNode.parentNode.querySelector('.comment-list');
                   commentList.classList.toggle('toggled');
                   if (commentList.classList.contains('toggled')) {
                     getComments(post_id, (comments) => {
                        console.log(comments);
                        setAllComments(comments)
                     })
                   }
                //  setCommentModalOpen({isOpen: true, post_id: post_id});
                }}><PostActionIcon  src={commentIcon}/>
                <Box key="comment-count">{commentCount || 0}</Box>
                </PostAction>

                {/* <PostAction key="connect-button" ><PostActionIcon  src={connectIcon}/>
                  <Box key="like-count">{connectionsCount}</Box>
                </PostAction> */}
             </Flex>
             <CommentList className='comment-list'>
               <Box className='comments-text'> Comments...</Box>
               <Box className='comments-container'>
                  {allComments.map(cmt => {
                     return <Box className='comments-item'>
                        <Box className='comments-item-message'>{cmt.message}</Box>
                        <Box className='comments-item-username'>{cmt.from.username}</Box>
                        <Box className='comments-item-date'>{millisecondsToTimeElapsedSince(Date.now() - cmt.date)}</Box>
                     </Box>
                  })}
               </Box>
               <CommentField className='comment-field'>
                  <CommentInput onChange={(e)=>{setNewComment(e.currentTarget.value)}}/>
                  <CommentButton onClick={()=>{
                     console.log("Adding comment")
                       addComment({post_id: post_id, comments: comments}, newComment, user, (reaction)=>{
                        console.log(reaction);
                        setAllComments(comments => ([...comments, reaction]))
                       // console.log(docRef, likeButton,  likeCount, reaction);
                       // likeButton.querySelector("div").textContent = likeCount + reaction.count;
                     }, "like");
                  }}>
                     SEND
                  </CommentButton>
               </CommentField>
             </CommentList>
         </Flex>
    </StyledFeedCard>);
}

