import React from 'react';
import styled , {css} from 'styled-components';

const StyledButton = styled.div`

  width:65px;
  height:65px;

    border-radius:50%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
  background:#666;
`;

export default props => {
  

  return (<StyledButton {...props}>+</StyledButton>);
}
