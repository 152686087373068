import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Flex, Box} from 'rebass';
import { Label, Checkbox, Radio, Slider } from '@rebass/forms'
import Button from '../components/Button.js';
import Options from '../components/Options.js';
import Score from '../components/Score.js';
import styled from 'styled-components';
import {withRouter} from 'react-router';

import RelevanceIcon from '../icons/relevance_filter.svg';
import RateIcon from '../icons/rate_filter.svg';
import RatingIcon from '../icons/rating_filter.svg';
import LocationIcon from '../icons/location_filter.svg';

const SectionTitle = styled(Box)`
   font-size:18px;
   padding:16px 0;
   color:#757575;
`;

const PropertyTitle = styled(Box)`
   background:#efefef;
   font-size:14px;
   color:#757575;
   text-transform:uppercase;
   padding:4px;
   margin: 16px 0 !important;
`;

const MoreJobsButton = styled(Box)`
  &{line-height: 2;
   letter-spacing: 0.35px;
   color: #ffc400;
   margin:0 auto !important;
   font-size: 14px;
   width: fit-content;
   } 
`;

const StyledLabel = styled(Label)`
   color:#9b9b9b;
   font-size:16px;
   padding:4px 0;
`;

const StyledOptions = styled(Options)`
   & > div{
      width: 25%;
      margin: 0;
      box-sizing: border-box;
      border: 0;
   }

   & > div .icon img{
      max-width: 70%;
      max-height: 70%;
   }

   & > div .label{
      padding: 16px 0;
      font-size:14px;
      color:#c3c3c3;
      font-weight: 600;
   }

   & > div.toggled .label{
      color: ${props => props.theme.darkYellow};
   }

   & > div .icon{border-color:#c3c3c3;}
   & > div.toggled .icon{border-color:${props => props.theme.darkYellow}}

   & > div div:nth-child(1){filter:saturate(0)}
   & > div.toggled div:nth-child(1){filter:saturate(1)}
   & > div div:nth-child(2){}


   & [data-value='relevance'] .icon img{position:relative; top:-5px; right:-5px; max-width:90%; max-height: 90%;}
`;

const StyledScore = styled(Score)`
   display:flex;
   padding:12px 0;
   margin:12px 0;
   justify-content: space-evenly !important;

   & img{width:42px !important;}
`;

const StyledSlider = styled(Slider)`
   padding:4px 0;
   
 -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  margin:16px 0;

  '&::-moz-range-thumb' {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
  }

 '&::-webkit-slider-thumb' {
   -webkit-appearance: none; /* Override default look */
   appearance: none;
   width: 25px; /* Set a specific slider handle width */
   height: 25px; /* Slider handle height */
   background: #04AA6D; /* Green background */
   cursor: pointer; /* Cursor on hover */
   }
   `

function Filters(props){
    props.setSection("Sort & Filter");

    const [filters, setFilter] = useState(props.filter);
    const [scoreFilter, setScoreFilter] = useState(props.filter.filter_by.rating);

    
    useEffect(()=>{
       console.log("Rendering filters", filters);
       if(props.filter.filter_by.titles.includes("all") && props.filter.filter_by.titles.length > 1){
          // All filter was set, and we added a new title filter
        //  let newFilter = props.filter;
         // newFilter.filter_by.title = newFilter.filter_by.title.filter(title =>{return title !== "all" ? true : false});

         // setFilter(newFilter);
       }
    })

const changeTitleFilter = e=>{
   
   let value = e.currentTarget.parentNode.parentNode.textContent;

   let newFilter = {...filters};

  // newFilter.filter_by.titles = newFilter.filter_by.titles.filter(title =>{return title !== "all" ? true : false});

   //Dumb way of doing this fast for the demo
   if(newFilter.filter_by.titles[0] == "all"){
      console.log("Converting ALl to array of titles");

      newFilter.filter_by.titles = ["Carpenter", "Electrician", "Mason", "Plumber", "Painter", "Pipefitter", "Steelworker", "Taper", "Stone Mason", "Crane Operator", "Boilmaker", "Building Inspector", "Railway worker", "Roofer", "Foreman", "Hazardous Material Worker", "Fence Builder", "Floor Tiler", "Driller"]
   }
  

   if(newFilter.filter_by.titles.includes(value)){
      newFilter.filter_by.titles = newFilter.filter_by.titles.filter( filterValue => {if(filterValue ==  value){return false}else{return true}});
   }else if(!newFilter.filter_by.titles.includes(value)){
      newFilter.filter_by.titles.push(value);
   }
   setFilter(newFilter);
   props.setFilter(newFilter);
   
   
  
}

   return <Flex flexDirection="column" justifyContent="space-between" height="100%" padding="16px" textAlign="left">
      <Box>
      <Box>
         <SectionTitle>SORT BY</SectionTitle>
         <StyledOptions data-value={filters} value={filters.sort_by ? filters.sort_by : null}options={[{name:"Relevance",value:"relevance", icon: <img src={RelevanceIcon}/>},{name:"Rate",value:"rate", icon: <img src={RateIcon}/>},{name:"Rating",value:"rating", icon: <img src={RatingIcon}/>},{name:"Location",value:"location", icon: <img src={LocationIcon}/>}]} action={(value)=>{
            let newFilter = {...filters};
            newFilter.sort_by = value;
            props.setFilter(newFilter);
            setFilter(newFilter);
         }}></StyledOptions>
      </Box>


      <Box>
      <SectionTitle>FILTER BY</SectionTitle>
         <PropertyTitle>Job Title</PropertyTitle> 
         <Flex justifyContent="space-between" padding="8px 0">
            <Box flexGrow={1}>
               <StyledLabel><Checkbox key="carpenter" onChange={changeTitleFilter} checked={filters.filter_by.titles.includes('Carpenter') ? true : filters.filter_by.titles.includes('all') ? true : false}/>Carpenter</StyledLabel>
               <StyledLabel><Checkbox  key="electrician" onChange={changeTitleFilter}  checked={filters.filter_by.titles.includes('Electrician') ? true : filters.filter_by.titles.includes('all') ? true : false}/>Electrician</StyledLabel>
               <StyledLabel><Checkbox  key="mason" onChange={changeTitleFilter}  checked={filters.filter_by.titles.includes('Mason') ? true : filters.filter_by.titles.includes('all') ? true : false}/>Mason</StyledLabel>
               <StyledLabel><Checkbox  key="plumber" onChange={changeTitleFilter}  checked={filters.filter_by.titles.includes('Plumber') ? true : filters.filter_by.titles.includes('all') ? true : false}/>Plumber</StyledLabel>
            </Box>
            <Box flexGrow={1}>
               <StyledLabel><Checkbox  key="painter" onChange={changeTitleFilter}  checked={filters.filter_by.titles.includes('Painter') ? true : filters.filter_by.titles.includes('all') ? true : false}/>Painter</StyledLabel>
               <StyledLabel><Checkbox  key="pipefitter" onChange={changeTitleFilter}  checked={filters.filter_by.titles.includes('Pipefitter') ? true : filters.filter_by.titles.includes('all') ? true : false}/>Pipefitter</StyledLabel>
               <StyledLabel><Checkbox   key="steelworker" onChange={changeTitleFilter} checked={filters.filter_by.titles.includes('Steelworker') ? true : filters.filter_by.titles.includes('all') ? true : false}/>Steelworker</StyledLabel>
               <StyledLabel><Checkbox   key="taper" onChange={changeTitleFilter} checked={filters.filter_by.titles.includes('Taper') ? true : filters.filter_by.titles.includes('all') ? true : false}/>Taper</StyledLabel>
            </Box>
         </Flex>
         <MoreJobsButton onClick={(e)=>{
            e.currentTarget.previousElementSibling.classList.add("toggled")
         }}>More Job Titles</MoreJobsButton>

         <PropertyTitle marginTop={"16px"}>Rating</PropertyTitle>

         <StyledScore score={scoreFilter} action={value=>{
            setScoreFilter(value+1);
            setFilter({filter_by:{...filters.filter_by, rating : value+1}});
            props.setFilter({filter_by:{...filters.filter_by, rating : value+1}});
         }}/>

         <PropertyTitle marginTop={"16px"}>Distance</PropertyTitle>

         <Box>
            <Flex width={"100%"} justifyContent="space-between" position="relative" fontSize="">
               <Box className="min-distance">0mi</Box>
               <Box className="set-distance" position={"absolute"} transform={"translateX(-16px)"} style={{position:"absolute", left:"calc(25% - 16px)"}}>25mi</Box>
               <Box className="max-distance">100mi</Box>
            </Flex>
            <StyledSlider defaultValue={25} minValue={0} maxValue={100}onChange={e=>{
               setFilter({filter_by:{...filters.filter_by, distance : e.currentTarget.value}});
               props.setFilter({filter_by:{...filters.filter_by, distance : e.currentTarget.value}});
               let setValue = window.document.querySelector(".set-distance");
               setValue.style.left = "calc("+e.currentTarget.value+"% - 16px)";
               setValue.textContent = e.currentTarget.value+"mi";
               console.log(e.currentTarget.value);
            }}/>
         </Box>

         <PropertyTitle marginTop={"16px"}>Union</PropertyTitle>
         <Box  padding="4px 0">
         <Label padding="4px 0"><Radio name="union" value="union"/> Union</Label>
         <Label padding="4px 0"><Radio name="union" value="non-union" defaultChecked={true} /> Non-Union</Label>
         <Label padding="4px 0"><Radio name="union" value="both"/> Both</Label>
         </Box>
      </Box>
      </Box>
         <Box background={"#fff"} padding={"16px"}><Button primary style={{position:"sticky", bottom:"16px"}}><Link to="/jobs">Apply</Link></Button></Box>
   </Flex>;
}


export default withRouter(Filters);