import React from 'react';
import {Flex} from 'rebass';
import styled , {css} from 'styled-components';
import star from '../icons/star_icon.svg';
import starOutline from '../icons/star_outline.svg';

const Star = styled.img`
    min-width:16px;
    width: 1em;
`;

export default function Score(props) {

    let stars = [];

    for(let i =0;i<5;i++){
        if(i<props.score){
            stars.push(<Star key={i} src={star} onClick={e=>{
                if(props.action){
                    props.action(i, e);
                }
            }}/>)
        }else if(!props.removeEmpty){
            stars.push(<Star key={i} src={starOutline} style={{fontSize:"0.9em"}} onClick={e=>{
                if(props.action){
                    props.action(i, e);
                }
            }}/>)
        }
       
    }

  return (<Flex alignItem="center" justifyContent="center" style={{fill:"#fff", padding:"8px 0", ...props.style}} {... props} minWidth={"5em"}>
      {stars}
  </Flex>);
}



