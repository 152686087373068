import React from 'react';
import {Flex, Text, Box} from 'rebass';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  useRouteMatch
} from "react-router-dom";

import styled from 'styled-components';
import FeedIcon from '../icons/feed_yellow.svg';
import UserIcon from '../icons/me_yellow.svg';
import JobsIcon from '../icons/jobs_yellow.svg';
import ChatIcon from '../icons/chat_yellow.svg';
import CameraIcon from '../icons/camera_yellow.svg';

const StyledLink = styled(NavLink)`
display: flex;
position:relative;
flex-direction: column;
justify-content: space-between;
height:100%;
    font-size:9px;
    color:#fff;
    text-decoration:none;
    padding:10px 16px;
    box-sizing:border-box;
    

    & .menu-icon{
      height: 30px;
    display: flex;
    align-items: center;
    align-content: center;
    filter: saturate(0) brightness(2);
    }

    &.is-active{
      color:rgb(246, 191, 0);

      & .menu-icon{filter:drop-shadow(0px 2px 4px rgba(0,0,0,0.5));}
    }
`;

const StyledIcon = styled.img`
    display:block;
    margin:0 auto;
`;

const Notification = styled.div`
      border-radius:50%;
    color:white;
    background:red;
    position:absolute;
    top:8px;
    right:8px;
    width:16px;
    height:16px;
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:1;
`;


export default function(props){

  let {path} = useRouteMatch();
  
    return(<Flex
        id="menu"
        px={2}
        color='#000'
        bg='#FFF'
        width="100%"
        minHeight="65px"
        style={{position:"relative",boxShadow: "0 0 7px 0 rgba(0, 0, 0, 0.42), 0 -1px 6px 0 rgba(150, 144, 111, 0.48)",
  backgroundImage: "linear-gradient(104deg, #ffee00 -7%, #ffd900 63%)", background:"black"}}
        alignItems='center' justifyContent="space-between" position="relative" top="0" left="0">
       
         <StyledLink key="feed"  activeClassName='is-active' to="/feed"><div className="menu-icon"><StyledIcon src={FeedIcon}/></div><div>Feed</div></StyledLink>
         <StyledLink key="jobs"  activeClassName='is-active' to="/jobs"><div className="menu-icon"><StyledIcon src={JobsIcon} /></div><div>Jobs</div></StyledLink>
         <StyledLink  key="post-create" activeClassName='is-active' to="/post/create"><div className="menu-icon"><StyledIcon src={CameraIcon}/></div><div>Camera</div></StyledLink>
         <StyledLink  key="chat" activeClassName='is-active' to="/chat">
         {props.chatNotifications > 0 ? <Notification>{props.chatNotifications}</Notification> : null}
         <div className="menu-icon"><StyledIcon src={ChatIcon}/></div><div>Chat</div></StyledLink>
         <StyledLink  key="me" activeClassName='is-active' to="/me"><div className="menu-icon"><StyledIcon src={UserIcon} /></div><div>Me</div></StyledLink>
       
        
      </Flex>)
}
