import React, {useState} from 'react';
import firebase from 'firebase';
import {Flex, Text, Box, LinkAnchor} from 'rebass';
import {Select} from '@rebass/forms';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useRouteMatch
} from "react-router-dom";

import styled from 'styled-components';

import Button from '../components/Button';
import Password from '../components/Password';
import Heading from '../components/Heading';
import Subheading from '../components/Subheading';
import Confirm from '../components/Confirm';
import Input from '../components/Input';
import {getIcon} from '../components/Icons';
const eyeIcon = getIcon("eye");
const StyledDiv = styled.div`
padding: 16px;
    justify-content: space-between;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-bottom: 50px;
`

const PasswordField = styled.div`
position:relative;
  & span{}
`;
const RevealPassword = styled.div`position:absolute; top:12px; right:8px; font-size:11px;`;



export default function SignIn(props){
    props.setSection("Sign In");

    let history = useHistory();

    const handleSignIn = e =>{
        props.setLoading(true);
        let email = document.querySelector("#email-test").value;
        let password = document.querySelector("#password-test").value;
        e.preventDefault();
  firebase.auth().signInWithEmailAndPassword(email, password).then(res => {
    if (res.user){
        
      let db = firebase.firestore();
                  let uid = firebase.auth().currentUser.uid;
                  var docRef = db.collection("users").doc(uid);
                  docRef.get().then(docs=>{
                    if (docs.exists) {
                      let userObject = docs.data();
                        //userObject.uid = uid;
                        props.setUser({...userObject});
                        props.setLoading(false);
                        history.push("/jobs");
                    }else {
                      // doc.data() will be undefined in this case
                      console.log("No such document!");
                      props.addModal(<Confirm title="User not found"></Confirm>);
                  }
                      }).catch(error=>{
                        props.addModal(<Confirm title="Log-in failed." type="alert">{error.error ? error.error.message : error.message}</Confirm>);
                      });

    } 
  })
  .catch(error=>{
    props.addModal(<Confirm isOpen={true} title="Log-in failed." type="alert">{error.error ? error.error.message : error.message}</Confirm>);
  });

    }
   return <StyledDiv>
     
            <Box width={1}>
                <Heading primary>What's your name and email?</Heading>
            </Box>
            <Box width={1}>
                <Input placeholder="Email" id="email-test"/>
                <Password placeholder="Password"  id="password-test"/>
            </Box>

            <Button primary onClick={handleSignIn}>Sign-in</Button> 

   </StyledDiv>;
}
