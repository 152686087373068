import React from 'react';
import styled from 'styled-components';
import {Box, Flex} from 'rebass';
import {Link} from 'react-router-dom';
import Subheading from '../components/Subheading';
import Button from '../components/Button';
import logo from '../logo.svg';
import background from '../workers_yellow_transparent.png';

  

const StyledHome = styled(Flex)`
    background: #fff url(${background}) no-repeat bottom /140%;


background-image: ${props => props.secondary ? "linear-gradient(99deg, #b60a0a 12%, #7a0000 90%)" : background};

padding:16px 16px 72px;
box-sizing:border-box;
`;

const GuestLink = styled(Link)`
font-size: 18px;
    font-weight: 600;
    color: #f6bf00;
    text-transform:uppercase;
    letter-spacing: 0.5px;
    padding: 0px 16px 0px 0px;
    text-decoration:none;
    margin-bottom:32px;
    display:block;
    `;

export default function Home(props){
    
    props.setSection("Home");
    return(<StyledHome height="100%" flexDirection="column" justifyContent="space-between">

<Box><img src={logo} className="App-logo" alt="logo" />
        <Subheading style={{textAlign:"center", fontSize:"18px"}}>CONNECTING CONSTRUCTION 
BUSINESSES WITH TRUSTED PROS</Subheading></Box>
<Box width={1}>

<GuestLink to="/register/guest" onClick={props.setUser(props.guestUser)} >Continue as guest</GuestLink>
<Button primary style={{margin:"0 0 1.5rem 0", fontSize:"18px"}}><Link to="/sign-in">Sign-In</Link></Button>
<Button primary  style={{fontSize:"18px"}}><Link to="/register">Register</Link></Button>

</Box></StyledHome>)}