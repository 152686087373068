import React from 'react';
import styled from 'styled-components';
import {Flex, Text, Box} from 'rebass';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
  useParams
} from "react-router-dom";

import menuIcon from "../icons/menu_icon.svg";
import userIcon from "../icons/user_icon.svg";
import backIcon from "../icons/back_arrow_icon.svg";
import searchIcon from "../icons/search_icon.svg";
import filterIcon from "../icons/filter_icon.svg";
import favoriteIcon from "../icons/yellow_heart.svg";

import Subheading from '../components/Subheading';
import { filter } from 'lodash';

// import pathToRegexp from 'path-to-regexp';

const UserAction = styled(Link)`
  margin:0 4px;
  &.toggled{
    opacity: 1;
    filter:saturate(0);
  }
`;

export default function(props){

  let {path} = useRouteMatch();
  let history = useHistory();

  const navRoutes = ["/feed","/me", "/me/saved", "/me/jobs", "/me/contacts", "/jobs","/chat", "/camera"];
  const hideRoutes = ["/", "register", "/register/congrats"];

  // let keys = [];
  // let regex = pathToRegexp('/\/+/w/', keys);
  // let routerRegex = regex.exec(path);

    // let jobId = window.location.pathname.split("job/");

    // if(jobId.length > 0){jobId = jobId[1]};

    let jobId = useParams();
  
    return(
    
     
      <Flex
            px={2}
            color='#000'
            bg='#FFF'
            width="100%"
            minHeight="65px"
            zIndex="1"
            style={{zIndex:1,position:"relative", boxShadow:"0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12)", padding:"0 16px"}}
            alignItems='center' justifyContent="space-between" position="relative" top="0" left="0">
           <Box>
              <Switch>
                <Route render={(data)=>{
                  if(navRoutes.includes(data.location.pathname)){
                    return <Link p={2} fontWeight='bold' to="/menu">
                    <img src={menuIcon}/>
                  </Link>
                  }else{
                    return <div onClick={()=>{
                      history.goBack();
                   }}> <img src={backIcon}/></div>
                  }
                }}>
                
                </Route>
    
               
              </Switch>
          </Box>
    
           <Box style={{padding:"0 16px", flexGrow:1}}><Subheading style={{textAlign:"left"}}>{props.sectionTitle}</Subheading></Box>
    
           <Box id="user-actions">
           <Switch>
           <Route path="/job/:jobId">
           <UserAction variant='nav'  to={window.location.pathname} onClick={e=>{
                if(! e.currentTarget.classList.contains("toggled")){
                  e.currentTarget.classList.add("toggled");
                  props.saveJob(jobId);
               
                props.setSnack({id: Math.random(),title: "Job Saved", actionText:"Unsave", action:()=>{
                  props.unSaveJob(jobId);
                  props.setSnack(null)
                }});
                }else{
                  e.currentTarget.classList.remove("toggled");
                  props.unSaveJob(jobId);
                  props.setSnack({id: Math.random(),title: "Job Unsaved", actionText:"Save", action:()=>{
                  props.saveJob(jobId);
                  props.setSnack(null)
                }});
                }
              }}>
              <img src={favoriteIcon}/>
            </UserAction>
           </Route>
                <Route exact path="/jobs">
                {/* <UserAction variant='nav' to='/profile'>
              <img src={userIcon} style={{filter:"invert(1)"}}/>
            </UserAction> */}
            <UserAction variant='nav' to='/jobs/search'>
              <img src={searchIcon}/>
            </UserAction>
                <UserAction p={2} fontWeight='bold' to="/jobs/filter">
                  <img src={filterIcon}/>
                </UserAction>
                
                </Route> 

                <Route path="/jobs/filter">
              
            <UserAction variant='nav' to='/jobs/filter' style={{textDecoration:"none", color: "#f6bf00", fontWeight:"600"}}>
              Reset all
            </UserAction>
             
                
                </Route> 

                          
              </Switch>
           </Box>
            
          </Flex>
    )
}
