import React, {useState, useEffect} from 'react';
import {Flex, Box} from 'rebass';

import { Label, Textarea } from '@rebass/forms'
import Button from '../components/Button.js';
import Subheading from '../components/Subheading.js';
import styled from 'styled-components';
import UserHeader from '../components/UserHeader';
import FeedList from '../components/FeedList';
import Map from './Map.js';
import Score from '../components/Score.js';
import Confirm from '../components/Confirm.js';
import UserPhoto from '../components/UserPhoto';
import EliteBadge from '../elite_badge.svg';
import SampleLogo from '../sample_logo.png';
import LocationIcon from '../icons/location_icon.svg';
import CalendarIcon from '../icons/calendar_icon.svg';
import HeartIcon from '../icons/heart_icon.svg';
import ShareIcon from '../icons/share_icon.svg';
import checkMark from '../icons/checkmark.svg'
import HardHatIcon from '../icons/hardhat.svg'
import CertificatesIcon from '../icons/certificates.svg'

import DescriptionIcon from '../icons/description.svg';
import CarpenterIcon from '../icons/carpenter.svg';
import RatingsIcon from '../icons/rating_filter.svg';
import InfoIcon from '../icons/info.svg';


import {Link, Switch, Route, useRouteMatch, useParams, Redirect} from 'react-router-dom';

import ApplicationsList from '../components/ApplicationsList';

import CompanyHeader from '../components/CompanyHeader.js';

const ProfilePhoto = styled(Box)`
position:relative;
width:116px;
height:116px;
border-radius:50%;
margin:0 auto;
background-image: url(${UserPhoto});
`;

const CertifiedBadge = styled(Box)`
    background-image:url(${EliteBadge});
    width:48px;
    height:48px;
    position:absolute;
    top:0;
    left:-24px;
    background-size:contain;
    background-repeat:no-repeat;
`;


const TabbedNavButton = styled(Box)`
    position: relative;
    font-size: 14px;
    color: #000;
   
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
    & a{
        color:#000;
        text-decoration: none;
        width:100%;
        height:100%;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    &.toggled:after{
        content:"";
        height:5px; 
        width:100%;
        background:#000;
        position:absolute;
        bottom:0;
        left:0;
    }
`;


const Title = styled.div`
     margin: 39px 2px 17px 10px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  color: #000;
  text-transform:uppercase;
    display:flex;
    align-items:center;
  & img{width:2em; margin-right:1em;}
`;

const YellowTitle = styled.div`
     margin: 39px 2px 17px 10px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  color: #f6bf00;
  text-transform:uppercase;
    display:flex;
    align-items:center;
  & img{width:2em; margin-right:1em;}
`;

const WhiteTitle = styled.div`
     font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  padding:16px 16px 0;
`;

const ReadMoreButton = styled.div`
     font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: #ffc600;
  padding:8px 16px;
`;

const FeedListStyled = styled(FeedList)`
    
    height:250px;
    background: transparent !important;

    & #feed-box{display:flex; flex-direction:row; align-items:flex-start;}
    & .feed-card{flex:0 0 auto; margin-right:16px; box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12); margin-bottom: 0 !important; min-width:80%;}
`;




const Image = styled(Box)`width:100px; height:100px;`;
const ModalTitle = styled(Box)`font-size:24px; text-align:center;`;
const Location = styled(Box)`color:#4a4a4a; font-size:14px; font-weight:400; text-align:center;`;
const StyledTextarea = styled(Textarea)`
    background: #d8d8d8;
    color:#4a4a4a;
    font-size:16px;
    border-radius:0;
    border:0;
    border-bottom: 1px solid #ffc600;
    width:100%;
    height:100%;
    min-height:150px;
    font-family: 'Roboto';
    line-height:1.4
`;

const StyledLabel = styled(Label)`
    color: rgba(0, 0, 0, 0.38);
    padding: 8px;
    background: #d8d8d8;
    margin-bottom:-1px;
    font-size:12px;
`;


const StyledUserHeader = styled(UserHeader)`
  text-align:center;
`;

export default function Detail(props){

    const UpgradeBanner = (props.user && props.user.membership !== "elite") ? <Box style={{color:"#f6bf00", fontSize:"14px", background:"black", padding:"16px", textTransform:"uppercase"}}><span style={{fontSize:"24px"}}>5</span> Applications Remaining* <span style={{textDecoration:"underline"}}>JOIN ELITE</span></Box> : null;

    

    let {path, url} = useRouteMatch();
    let { userId } = useParams();


    let user = props.users.filter(user=>{if(user.user_id == userId){return true}});

    if(user.length == 1){
        user = user[0];
    }

    props.setSection("Pro Details");


    useEffect(()=>{
        document.querySelector(".App-header").scrollTop = 0;
    })
    


    
    if(props.users.length == 0){return <Redirect to="/"/>}


    let aboutText = user.about;

    if(user.company){
        console.log(props.companies)
        let company = props.companies.filter(c=>{return(c.name == user.company ? true : false)});
        if(company.length >0){
            aboutText = company[0].description;
        }
    }


    console.log(props.users)
    let similarPros = props.users.filter(userItem=>{
        if(userItem.title == user.title && userItem.user_id !== user.user_id){
            return true
        }
    });

    similarPros = similarPros.map(user =>{
        user.type = "user";
        return user;
    })



    return(<Box  style={{textAlign:"left"}}>
        
        <Flex flexDirection="column"  justifyContent="space-around" style={{backgroundImage:"linear-gradient(127deg, #ffee00 12%, #f6bf00 76%)", borderBottom:"solid 1px #666"}}>
        <StyledUserHeader user={user} style={{padding:"24px 0 8px"}}/>
         </Flex>

    <Flex style={{padding:"16px"}}>
             <Box style={{textAlign:"left", flex:"0 60%"}}>
                 
              
                 <Box style={{fontSize:"24px", fontWeight:"600"}}>{user.title}</Box>
             
                
                 <Box style={{fontSize:"16px", color:"#000", fontWeight:"bold"}}>I.D. {user.user_id}</Box>
                 <Box style={{fontSize:"14px", color:"#4a4a4a"}}>{user.location}</Box>
              
             </Box>
             <Flex style={{textAlign:"right", flex:"0 40%"}} flexDirection="column" justifyContent="space-between">
{/* <Box>{user.company ?    <Box style={{fontSize:"20px", fontWeight:"bold"}}>{"$"+user.rate+"/h"}</Box> : <img src={SampleLogo}/>}</Box> */}
                 <Box>
                     <Box style={{color:"#4a4a4a", fontSize:"12px"}}><img src={LocationIcon}/> {user.distance ? user.distance+"mi" : "Unavailable"}</Box>
                     <Box style={{color:"#4a4a4a", fontSize:"12px"}}><img src={CalendarIcon}/> {user.date ? "Joined "+ user.date : "Unavailable"}</Box>
                 </Box>
             </Flex>
         </Flex>

        

         <Box>
             <YellowTitle><img src={HardHatIcon}/> Professional Experience</YellowTitle>
             <ul style={{listStyle:"none", padding:"0 0 0 16px"}}>
                <li style={{padding:"0 0 8px 0"}}><img src={checkMark} style={{marginRight:"24px"}}/> Master Carpenter - 10 yrs</li>
                <li style={{padding:"0 0 8px 0"}}><img src={checkMark} style={{marginRight:"24px"}}/> Carpenter - 5 yrs</li>
                <li style={{padding:"0 0 8px 0"}}><img src={checkMark} style={{marginRight:"24px"}}/> Masonry - 3 yrs</li>
             </ul>
         </Box>

         <Map id="jobs-map" jobs={[user]} style={{pointerEvents:"none", height:"250px"}} detail={true}/>

         <Box>
<Title><img src={InfoIcon}/>  About Me</Title>
             <p style={{padding:"0 16px", WebkitLineClamp:"5", display:"-webkit-box", WebkitBoxOrient:"vertical", overflow:"hidden"}}>{aboutText}</p>
            <ReadMoreButton onClick={event=>{
                if(event.currentTarget.textContent == "Read More"){
                    event.currentTarget.previousElementSibling.style.display = "block";
                    event.currentTarget.textContent = "Read Less";
                }else{
                    event.currentTarget.previousElementSibling.style.display = "-webkit-box";
                    event.currentTarget.textContent = "Read More"
                }
           
            }}>Read More</ReadMoreButton>
           
         </Box>

        <Box>
             <YellowTitle><img src={CertificatesIcon}/> License And Certificate</YellowTitle>
             <ul>
                <li>{user.certificates}</li>
             </ul>
         </Box>

         <Box>
             <YellowTitle><img src={RatingsIcon}/> Ratings and Reviews</YellowTitle>
             <p style={{padding:"0 16px", WebkitLineClamp:"5", display:"-webkit-box", WebkitBoxOrient:"vertical", overflow:"hidden"}}>I’ve been a carpenter for over 15 years. I specialize in Morbi aliquam elit sit amet purus porta condimentum. Donec in consectetur libero. Phasellus augue libero, rhoncus a posuere et, placerat quis dui...</p>
            <ReadMoreButton onClick={event=>{
                if(event.currentTarget.textContent == "Read More"){
                    event.currentTarget.previousElementSibling.style.display = "block";
                    event.currentTarget.textContent = "Read Less";
                }else{
                    event.currentTarget.previousElementSibling.style.display = "-webkit-box";
                    event.currentTarget.textContent = "Read More"
                }
           
            }}>Read More</ReadMoreButton>
         </Box>

         {similarPros.length > 0 && <Box style={{backgroundColor:"#ffd900"}}>
         
             <Title style={{letterSpacing:"0", margin:0, fontWeight:"600", textTransform:"unset", padding:"16px 16px 0"}}>Similar Pros</Title>
             <FeedListStyled jobs={similarPros} users={props.users} companies={props.companies} favorites={props.favorites} contact={props.contact}/>
         </Box>}

         <Box style={{padding:"16px 16px 24px", position:"sticky", bottom:"0"}}>
<Button primary onClick={()=>{props.contact(user);}}>{user.company ? "Apply" : "Contact"}</Button>
         </Box>
 
    </Box>);
}