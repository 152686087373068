import React from 'react';
import styled from 'styled-components';
import {Flex, Box} from 'rebass';

const ShareMenu = styled(Flex)`
position: fixed;
    left: 0;
    bottom: 65px;
    width: 100%;
    background: #fff;
    box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.6);
 
    flex-direction: column;
    padding: 12px 12px 24px;
    z-index:2;

   
`;

const MenuBackground = styled(Box)`
    background: rgba(0,0,0,0.6);
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top:0;
    }
`
;
const ShareButton = styled(Box)`
    font-size: 36px;
`;

const MenuLabel = styled.div`
    font-size: 10px;
    color: #ccc;
    margin-bottom: 12px;
`;

const ShareLabel = styled(Box)`
        font-size: 11px; 
        color: #ccc;
    ;`

export default function({isOpen, setShareMenuOpen}){
    return  <>{isOpen ? <><MenuBackground onClick={(e)=>{
        setShareMenuOpen(false)
    }}/><ShareMenu className="share-menu">
        <Flex><MenuLabel>SHARE TO...</MenuLabel></Flex>
        <Flex alignItems={"center"} justifyContent={"space-around"}>
         
        <ShareButton className="share-menu-item"  onClick={(e)=>{
        setShareMenuOpen(false)
    }}>
            <i className="fab fa-facebook"></i>
            <ShareLabel>Facebook</ShareLabel>
            </ShareButton>
        <ShareButton className="share-menu-item" onClick={(e)=>{
        setShareMenuOpen(false)
    }}>
            <i className="fab fa-instagram"></i>
            <ShareLabel>Instagram</ShareLabel>
            </ShareButton>
        <ShareButton className="share-menu-item" onClick={(e)=>{
        setShareMenuOpen(false)
    }}>
            <i className="fab fa-linkedin"></i>
            <ShareLabel>LinkedIn</ShareLabel>
            </ShareButton>
        <ShareButton className="share-menu-item" onClick={(e)=>{
        setShareMenuOpen(false)
    }}>
            <i className="far fa-copy"></i>
            <ShareLabel>Copy</ShareLabel>
            </ShareButton></Flex>
    </ShareMenu></> : null}</>
}