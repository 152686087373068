import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Flex, Text, Box, LinkAnchor} from 'rebass';
import {Label, Select} from '@rebass/forms';
import {
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";

import Button from '../components/Button';
import Heading from '../components/Heading';
import Input from '../components/Input';
import Pagination from '../components/Pagination'

import Map from './Map';

import RegularModal from '../components/Modal3.js';
import Confirm from '../components/Confirm.js';
import Modal from '../components/Modal2.js';

import UserPhoto from '../components/UserPhoto';

import { ModalProvider , BaseModalBackground} from 'styled-react-modal';
import elite_image from '../elite_badge.svg';

import workers_image from '../workers_yellow_transparent.png';

import {infoIcon, awardIcon, rightChevron, CertificatesIcon, RatingIcon, Skills as SkillsIcon, Description as DescriptionIcon} from '../components/Icons';

const SpecialModalBackground = styled(BaseModalBackground)`
  background:none;
`;



const EliteBadge = styled.div`
  background-image:url('${elite_image}');
  background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100px;
    height: 120px;
    margin: 0 auto;
`;

const Logo = styled.img`
  width:70px;
  margin:0 auto;

`;

const EliteInfoModal = styled(RegularModal)`
 background:#111 url(${workers_image}) no-repeat center/cover;
 padding:16px;
 height:auto;
`;

const WhiteHeading = styled(Box)`
  color:#fff;
  text-transform:uppercase;
  font-size:18px;
`;

const StyledList = styled.ul`
  & li{
    color: #f6bf00;
    font-size:14px;
    padding:8px 0;
  }
`;

const SectionTitle = styled(Flex)`
align-items:center;
     margin: 39px 2px 17px 10px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  color: #000;
  text-transform:uppercase;
    display:flex;
    align-items:center;
  & img{width:1em; margin-right:1em;}
`;

const Steps = [
    {index: 0,
      path:"/register",
    component:"Step 1",    
    },
    {path:"/register/email-password",
    component:"Step 2",    
    },
    {path:"/register/phone-number",
    component:"Step 3",    
    },
    {path:"/register/phone-confirmation",
    component:"Step 4",    
    },
    {path:"/register/congrats",
    component:"Step 5",    
    },
    {path:"/register/become-elite",
    component:"Step 5",    
    },
    {path:"/register/full-name",
    component:"Step 5",    
    },
    {path:"/register/home-address",
    component:"Step 5",    
    },
    {path:"/register/ssn",
    component:"Step 5",    
    },
    {path:"/register/profile-picture",
    component:"Step 5",    
    },
];

const GrayLink = styled.div`
    font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.38);
`;

const ContinueButton = styled.div`
  color:#f6bf00;
  font-size:14px;
  box-sizing:border-box;
  width:100%;
  padding:16px;
  text-transform:uppercase;
  text-align:right;
`;

const StyledTextAndOptions = styled(Flex)`
  width: 100%;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.12);
    padding: 12px;
    color: rgba(0,0,0,0.33);
    color: #000;
    font-size: 16px;
    margin: 0 0 1rem;
    box-sizing: border-box;
    position:relative;

    & img{position:absolute; right:0; top:50%; transform:translateY(calc(-50% - 0.5rem))}
`;

const StyledSelect = styled.select`
border: 0;
font-size: 16px;
padding: 12px 0;
margin-right: -14px;
background:#fff;
flex-grow:1;
`;

export default function PostJob(props){

    props.setSection("New Job");

    const[nextIndex, setPage] = useState(0);

    const[newJobObject, setNewJobObject] = useState({location:"Midtown NY", company:"Turner", date: new Date(), lat:40.64, lng:-74.47, owner_id: props.user.uid});
    const[registrationSteps, setStepCount] = useState(2);
    const[currentStep, setCurrentStep] = useState(1);
    const[eliteModalOpen, setEliteModalOpen] = useState(false);
    const[finishProfileOpen, setFinishModalOpen] = useState(false);
    const[location, setLocation] = useState(null);
    const[jobTitle, setJobTitle] = useState('master');
    const[skillLevel, setSkillLevel] = useState('master');

    //const[filter, setFilter] = useState("all");
    let setFilter = props.setFilter;

    let { path, url } = useRouteMatch();
   
    let { step } = useParams();

    let toggleModal = props.toggleModal;


    useEffect(()=>{
     
    })

    const  findAddress = (position, callback) => {
      var lat = position.coords.latitude;
      var long = position.coords.longitude;
      var url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + long + "&key=AIzaSyD2CUeK2bhXw_En1VXdPhYRgrB0mHqmW7c";
      let location = fetch(url).then(results=>{
        return results.json()
      }).then(json=>{
       callback(json); 
      });
    }


    return(
      <><ModalProvider backgroundComponent={SpecialModalBackground}>
            
      <Modal  key="finish-modal" isOpen={finishProfileOpen} backgroundProps={{style:{backgroundColor:"none"}}} afterClose={()=>{setFinishModalOpen(false); setCurrentStep(5)}}></Modal>
      
      
      </ModalProvider>
      
    
      
    <Flex flexDirection="column" justifyContent="space-between" height="100%" style={{position:"relative", padding:"32px 16px"}}>



        {!["/register/congrats"].includes(window.location.pathname ) ?  <Pagination style={["/register", "/register/guest"].includes(window.location.pathname) ? {top:"84px", left:"16px", position:"absolute"} : {}} key="pagination" pages={["/register", "/register/guest"].includes(window.location.pathname) ? 0 : registrationSteps} current={currentStep}/> : null}
    
          <Switch>


            

            

            <Route exact path="/jobs/new">
            <Box width={1}>
            <Heading primary>Post a job</Heading>
</Box>
<Box>
<Box width={1}>
<SectionTitle style={{fontSize:"18px", fontWeight:"bold", textAlign:"left"}}><img src={DescriptionIcon}/> JOB DETAILS</SectionTitle>
                <Input placeholder="Company" onChange={e=>{
                  setNewJobObject({...newJobObject, company:e.currentTarget.value});
                }}/>
                <Input placeholder="Job title - i.e. Carpenter" onChange={e=>{
                  setNewJobObject({...newJobObject, title:e.currentTarget.value});
                }}/>
                <Input placeholder="Rate - $/hr"  onChange={e=>{
                 setNewJobObject({...newJobObject, rate:e.currentTarget.value});
                }}/>
                <Input placeholder="Description" onChange={e=>{
                  setNewJobObject({...newJobObject, description:e.currentTarget.value});
                }}/>
                <Input placeholder="Address" onChange={e=>{
                  setNewJobObject({...newJobObject, address:e.currentTarget.value});
                }}/>
                </Box>

                
                </Box>
                <SectionTitle style={{fontSize:"18px", fontWeight:"bold", textAlign:"left"}}><img src={SkillsIcon}/> SKILL LEVEL</SectionTitle>
                <Flex style={{borderBottom:"1px solid rgba(0,0,0,0.12)", paddinBottom:"12px", marginBottom:"16px", textTransform:"capitalize", textAlign:"left"}}>
             
                  <StyledSelect onChange={e=>{   setNewJobObject({...newJobObject, level:e.currentTarget.value});}}>
                    <option>Master</option>
                    <option>Intermediate</option>
                    <option>Notice</option>
                    </StyledSelect>
                <Box style={{background:"#fff", padding:"10px 0", pointerEvents:"none"}}><img src={rightChevron}/></Box>
</Flex>
                   
          

                <Box>
                {/* <Map detail={true} action={location=>{
                }}/> */}
                </Box>
                   
            <Button primary onClick={()=>{setCurrentStep(2)}}><Link to={`${path}/certificates`}>Next</Link></Button> 
            </Route>
                <Route path="/jobs/new/certificates">
            <Box width={1}><Heading primary>Add licenses & Certificates</Heading></Box>
            <Box>
             <SectionTitle><img src={CertificatesIcon}/> LICENSES </SectionTitle>
             <StyledTextAndOptions  width={1} onClick={()=>{
  props.createOptionsModal([
    {name:"AIC", value:"AIC"},
    {name:"Airial Lift Training", value:"AIC"},
    {name:"CCM", value:"AIC"},
    {name:"CMCI", value:"AIC"},
    {name:"Crane Operators Certificate", value:"AIC"},
    {name:"LEED", value:"AIC"},
    {name:"NCEES", value:"AIC"},
    {name:"OSHA", value:"AIC"},
    {name:"PMP", value:"AIC"},
    ],  (value)=>{
     
      setNewJobObject({...newJobObject, licenses:value[0].name})
   },{title:"Choose Certificate"})
}}>{newJobObject.licenses || "Select Licenses"}<img src={rightChevron}/></StyledTextAndOptions>
         </Box>

         <Box>
             <SectionTitle><img src={SkillsIcon}/> CERTIFICATES</SectionTitle>
           

<StyledTextAndOptions  width={1} onClick={()=>{
  props.createOptionsModal([
    {name:"AIC", value:"AIC"},
    {name:"Airial Lift Training", value:"AIC"},
    {name:"CCM", value:"AIC"},
    {name:"CMCI", value:"AIC"},
    {name:"Crane Operators Certificate", value:"AIC"},
    {name:"LEED", value:"AIC"},
    {name:"NCEES", value:"AIC"},
    {name:"OSHA", value:"AIC"},
    {name:"PMP", value:"AIC"},
    ],  (value)=>{
      setNewJobObject({...newJobObject, certificates:[...value]})
   },{title:"Choose Certificate"})
}}>{newJobObject.certificates || "Select Certificates"}<img src={rightChevron}/></StyledTextAndOptions>

         </Box>




         <Button knockedOut  style={{fontSize:"16px", margin:"0 0 16px 0"}} onClick={()=>{
    props.addJob({...newJobObject, job_id: window.hh.jobs.length+1});
}}><Link to="/me/jobs">Skip</Link></Button> 
<Button  key="submit-button" style={{fontSize:"16px"}} onClick={()=>{
    props.addJob({...newJobObject, job_id: window.hh.jobs.length+1});
}} primary><Link to="/me/jobs">Submit</Link></Button>

           
</Route>
            
          </Switch>
     
      </Flex></>
      
      )
}
