import React from 'react';
import {Flex, Box} from 'rebass';
import Subheading from '../components/Subheading.js';
import styled from 'styled-components';
import UserPhoto from './UserPhoto.js';
import Score from './Score.js';

const StyledPhoto = styled.div`
    & div{margin:0 auto}
`;
export default function(props){
    let firstName = (props.user && props.user.first_name)  ? props.user.first_name : "Guest";
    let middleName = (props.user && props.user.middle_name) ? props.user.middle_name.slice(0,1) + "." : "";
    let lastName = (props.user && props.user.last_name) ? props.user.last_name : "User";

    if(props.user && props.user.membership === "free"){
        firstName = props.user.email;
        middleName = "";
        lastName = "";
    }

    if(props.user && props.user.type === "employer"){
        firstName = props.user.email;
        middleName = "";
        lastName = "";
    }

    return(<Box style={props.style} className={props.className}>
    <StyledPhoto><UserPhoto user={props.user}/></StyledPhoto>
    <Box >
        <Subheading style={{margin:"0 auto", textAlign:"center"}}>{firstName +" "+middleName+" "+lastName}</Subheading>
        <Box style={{fontSize:"12px"}}>{props.user ? props.user.location : null}</Box>
        <Box><Score score={props.user ? props.user.rating : null} /></Box>
    </Box>
    </Box>)
}
