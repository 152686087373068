import React from 'react';
import {Flex, Box} from 'rebass';
import {Link, useRouteMatch, Switch, Route, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import profileIcon from '../icons/edit_profile.svg';
import notificationsIcon from '../icons/notifications.svg';
import privacyIcon from '../icons/privacy_policy.svg';
import feedbackIcon from '../icons/feedback.svg';
import signoutIcon from '../icons/sign_out.svg';
import { Switch as SwitchComponent }  from '@rebass/forms';
import Confirm from "../components/Confirm"
import Button from "../components/Button"
import firebase from "firebase";

import EditProfile from './EditProfile';
import {version as versionNumber} from '../../package.json';

const MenuItem = styled(Box)`
border-bottom:solid 1px rgba(0, 0, 0, 0.12);
padding:12px 0px;
display:flex;
align-items:center;
justify-content:space-between;

& img{width:24px; margin-right:16px;}

& a{color:inherit; text-decoration:none; display: flex;
    height: 100%;
    align-items: center;
    width: 100%;}
`;

const PrivacyPolicy = styled.div`
    text-align:left;
    & a{color:#0091ff}
`;

const StyledTextarea = styled.textarea`
    font-family: 'Roboto', sans-serif;
    font-size:18px;
    padding: 16px;
    color: #000;
    flex-grow: 1;
    margin: 8px 0 16px;
    border: 1px solid #000;
    resize: none;
`;


export default function Menu(props){

    let {path, url} = useRouteMatch();
    const history = useHistory();
    
    if(window.location.pathname == "/menu"){
        console.log(path, url)
        props.setSection("Settings");
    }
   

    let setSection = props.setSection;
    return(<Flex style={{letterSpacing:"0.34px", padding:"16px 16px 16px", height:"100%"}} flexDirection="column">
       

        <Switch>
      
            <Route exact path="/menu">
                <MenuItem onClick={()=>{setSection("Edit Profile");}}><Link to={path+"/edit-profile"}><img src={profileIcon}/>Edit Profile</Link></MenuItem>
                <MenuItem onClick={()=>{setSection("Notifications");}}><Link to={path+"/notifications"}><img src={notificationsIcon}/>Notifications</Link></MenuItem>
                <MenuItem onClick={()=>{setSection("Privacy Policy");}}><Link to={path+"/privacy"}><img src={privacyIcon}/>Privacy Policy</Link></MenuItem>
                <MenuItem onClick={()=>{setSection("Feedback");}}><Link to={path+"/feedback"}><img src={feedbackIcon}/>Feedback</Link></MenuItem>
                <MenuItem onClick={()=>{
                    props.addModal(<Confirm title="Are you sure you want to sign out?" isOpen={true} confirmLabel="SIGN-OUT" cancelLabel="CANCEL" onConfirm={()=>{
                      

//const auth = firebase.getAuth();
firebase.auth().signOut().then(() => {
  // Sign-out successful.
}).catch((error) => {
  // An error happened.
});

                        history.push("/");
                    }}><p></p></Confirm>);
                    
                    }}><Link><img src={signoutIcon}/>Sign-out</Link></MenuItem>

<Box style={{position:"absolute", opacity:"0.5", bottom:"16px", textAlign:"center", width:"100%"}}>v{versionNumber}</Box>
            </Route>

            <Route path="/menu/privacy">
                <PrivacyPolicy>
                <p><b>Your information.</b> You may update or correct your online account information by logging into your online account and updating your information. You may also email us at <a>privacy@hiringhallapp.com</a> if you wish to deactivate your online account, but note that we may retain information about you for legitimate business purposes or as required by law.</p>

<p><b>Promotional Communications.</b> You may opt out of the receiving promotional communications from Hiring Hall by following the instructions in those communications or changing your preferences via <a>https:www.hiringhallapp.com/help/subscriptions</a>. If you opt out of receiving promotional communications, we may still send you non-promotional emails, such as emails about your account, membership or our ongoing business relations.</p>

<p><b>Location Information.</b> The settings of your Internet browser or mobile device may allow you to stop sharing your precise location with Hiring Hall or others. You can also stop our collection of location information in connection with our mobile applications by following the standard uninstall process and removing all Hiring Hall applications from your device.</p>

<p><b>Mobile Push Notifications/Alerts.</b> With your consent, we may send promotional and non-promotional push notificiations or alerts to your mobile device. You can deactivate these messages at any time by changing the notifications settings on your mobile device.</p>
                </PrivacyPolicy>
            </Route>

            <Route path="/menu/feedback" style={{height:"100%"}}>
               <StyledTextarea placeholder="Write your feedback for the Hiring Hall team..."/>
               <Button primary>Send Feedback</Button>
            </Route>


            <Route path="/menu/notifications">
               <MenuItem>Push notifications <SwitchComponent onClick={(value)=>{console.log(value)}}/></MenuItem> 
               <MenuItem>Email notifications <SwitchComponent/></MenuItem> 
               <MenuItem>Chat notifications <SwitchComponent/></MenuItem> 
            </Route>

            <Route path="/menu/edit-profile">
               <EditProfile setSection={props.setSection} user={props.user} setUser={props.setUser} updateUser={props.updateUser}/>
            </Route>
        </Switch>

    </Flex>);
}