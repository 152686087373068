import React from 'react';
import styled from 'styled-components';
import Input from './Input'

const SingleDigitInput = styled(Input)`
    margin: 0px 4px;
    text-align: center;
    font-size: 32px;
    color: rgb(102, 102, 102);
    outline: none;
    padding:0;
    width:1.2em;
`;


export default (props) =>{
    return(<SingleDigitInput key={Math.random()} {...props} maxLength={1} onChange={e=>{
        if(e.currentTarget.value === "" && e.currentTarget.previousElementSibling){
            e.currentTarget.previousElementSibling.focus();
        }else if(e.currentTarget.nextElementSibling){
          e.currentTarget.nextElementSibling.focus();
        }
      }}/>)
}

