import React from 'react';
import styled from 'styled-components';


const StyledModal = styled.div`
  
width: 20rem;
height: 20rem;
display: flex;
flex-direction:column;
align-items: center;
justify-content: space-around;
background-color: #fff;
border-radius: 25px;
box-shadow: 0 7px 27px 0 #d3c07f;
padding:1rem;
box-sizing:border-box;
text-align:left;
position:absolute;
z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

`;
export default function(props) {
 

  return (<StyledModal {...props}>{props.children}</StyledModal>);
}



