import React, {useState} from 'react';
import Input from './Input';
import styled from 'styled-components';


const PasswordField = styled.div`
position:relative;
  & span{}
`;
const RevealPassword = styled.div`position:absolute; top:12px; right:8px; font-size:11px;`;



export default function Password(props){

    const [visible, setVisible] = useState(false);
    return(
    <>
    <PasswordField>
   <Input {...props} type={visible ? "text" : "password"} placeholder="Password"  id="password-test" onBlur={e=>{
       setVisible(false);
   }}/>
    <RevealPassword onClick={(e)=>{
        setVisible(!visible);
                }}>
                {/* {<img src={eyeIcon}/>} */}
                {visible ? "HIDE" : "VIEW"}
    </RevealPassword></PasswordField>
    </>)
    };